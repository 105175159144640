import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepButton, StringOptions } from '@lainaedge/platformshared';

import { RenderHelper } from '../RenderHelper';

/**
 * Props for [[`PageButton`]] component
 */
export interface PageButtonProps {
  /** Step data */
  step: StepButton;
  /** Logic for the button. */
  logic: any;
  /** Used for redirecting. */
  renderer: RenderHelper;
  /** String used for redirecting.
   *
   * @example
   * "page/enroll"
   */
  targetAction: string;
}

/**
 * PageButton component.
 *
 * @remarks
 * Main action button of the page.
 *
 * @component PageButton
 * @category PageElements
 */
export default class PageButton extends Component<PageButtonProps> {
  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to targetAction.
   */
  handleClick = async () => {
    const step = this.props.step;
    if (step.options.checkOption('GoToWebSite')) {
      window.location.href = this.props.targetAction;
    } else {
      this.props.renderer.navigate(this.props.targetAction);
    }
  };

  /**
   * Renders PageButton class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;

    const opt = new StringOptions(step.option_2);
    let baseButtonStyle = 'btn w-xs waves-effect waves-light';

    if (opt.checkOption('Primary')) baseButtonStyle += ' btn-primary';
    else if (opt.checkOption('Secondary')) baseButtonStyle += ' btn-secondary';
    else if (opt.checkOption('Danger')) baseButtonStyle += ' btn-danger';
    else if (opt.checkOption('Warning')) baseButtonStyle += ' btn-warning';
    else if (opt.checkOption('Info')) baseButtonStyle += ' btn-info';
    else if (opt.checkOption('Light')) baseButtonStyle += ' btn-light';
    else if (opt.checkOption('Dark')) baseButtonStyle += ' btn-dark';
    else baseButtonStyle += ' btn-success';

    const isColorOptionn = [
      'Primary',
      'Secondary',
      'Danger',
      'Warning',
      'Info',
      'Light',
      'Dark',
    ].includes(step.option_2);

    return (
      <button
        type="button"
        className={opt.checkOption('Selected') ? baseButtonStyle + ' active' : baseButtonStyle}
        onClick={this.handleClick}
      >
        {step.option_2 && !isColorOptionn && (
          <i className={step.option_2 + ' btn-label label-icon mr-2'}></i>
        )}
        {step.text}
      </button>
    );
  }
}
