import React, { useState } from 'react';
import { Row, Col, Alert, Spinner, Label } from 'reactstrap';

import { Link } from 'react-router-dom';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// action
import { resetPasswordWithCode, participantResetPasswordRequest } from '../../services';

import { useAuth } from '../../context/AuthContext';
import { ChangePasswordResult } from '@lainaedge/platformshared/src/types/ChangePasswordResult';

import { IParticipantForgetPWFormFields, IResetPWFormFields } from '../../store/types';

import Layout from '../../components/StudyNonAuthLayout';

/**
 * Participant/ForgotPassword component.
 *
 * @remarks
 * Page where participant can reset his password
 *
 * @component ForgotPassword
 * @category Page
 */
const ForgetPasswordPage = (): JSX.Element => {
  const { setError, success, setSuccess, clearState } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [pwdError, setPwdError] = useState<string>('');

  // handleValidSubmit
  async function handleValidSubmit(values: Record<string, any>) {
    clearState();
    SetIsSubmitting(true);
    let res1: boolean;
    let res2: ChangePasswordResult;
    if (!isNextStep) {
      setEmail(values.email);
      res1 = await participantResetPasswordRequest(values as IParticipantForgetPWFormFields);
      if (res1) {
        setIsNextStep(true);
        setSuccess('A verification code has been sent to the email.');
      } else {
        setError('Username or email is not correct');
      }
    } else {
      setPwdError('');
      if (values.password !== values.password_confirmation) {
        setPwdError('Confirm Password does not match');
        SetIsSubmitting(false);
        return;
      }
      res2 = await resetPasswordWithCode({
        ...values,
        verificationCode: parseInt(values.verificationCode),
      } as IResetPWFormFields);
      if (res2.success) {
        setSuccess('The password has been reset.');
      } else {
        setPwdError(res2.message!);
      }
    }
    SetIsSubmitting(false);
  }

  const handleClearState = () => {
    clearState();
  };

  const gotoPrevStep = () => {
    clearState();
    setEmail('');
    setPwdError('');
    setIsNextStep(false);
  };

  return (
    <Layout>
      <div>
        <div>
          {pwdError && pwdError ? (
            <Alert color="danger" style={{ marginTop: '13px' }}>
              {pwdError}
            </Alert>
          ) : null}
          {success ? (
            <Alert color="success" style={{ marginTop: '13px' }}>
              {success}
            </Alert>
          ) : null}

          {!isNextStep ? (
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Enter Email'),
              })}
              onSubmit={(values) => {
                handleValidSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form className="form-horizontal">
                  <h3 className="text-center mb-5">Forgot Password?</h3>
                  <div className="mb-3">
                    Enter your username below and we will send you password reset link
                  </div>
                  <div className="form-group">
                    <Label for="email" className="form-label">
                      Email
                    </Label>
                    <Field
                      name="email"
                      type="input"
                      required
                      placeholder="Enter Email"
                      className={
                        'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>

                  <Row className="form-group">
                    <Col className="text-right">
                      <button
                        className="btn btn-login btn-block w-md waves-effect waves-light save-btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Next
                        {isSubmitting && <Spinner className="spinner" />}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: email,
                verificationCode: '',
                password: '',
                password_confirmation: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Enter Email'),
                verificationCode: Yup.string().required('Enter Code'),
                password: Yup.string().required('Enter Password'),
                password_confirmation: Yup.string().required('Enter Password Confirmation'),
              })}
              onSubmit={(values) => {
                handleValidSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form className="form-horizontal">
                  <h3 className="text-center mb-5">Set Password</h3>

                  <div className="form-group">
                    <Label for="email" className="form-label">
                      Email
                    </Label>
                    <Field
                      name="email"
                      type="input"
                      required
                      disabled
                      placeholder="Enter Email"
                      value={email}
                      className={
                        'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>

                  <div className="form-group">
                    <Label for="verificationCode" className="form-label">
                      Code
                    </Label>
                    <Field
                      name="verificationCode"
                      type="input"
                      required
                      placeholder="Enter Code"
                      className={
                        'form-control' +
                        (errors.verificationCode && touched.verificationCode ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage
                      name="verificationCode"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <Label for="password" className="form-label">
                      New Password
                    </Label>
                    <Field
                      name="password"
                      type="password"
                      required
                      placeholder="Enter new password"
                      className={
                        'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                      }
                    />
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                  </div>

                  <div className="form-group">
                    <Label for="password_confirmation" className="form-label">
                      Confirm Password
                    </Label>
                    <Field
                      name="password_confirmation"
                      type="password"
                      required
                      placeholder="Enter confirm password"
                      className={
                        'form-control' +
                        (errors.password_confirmation && touched.password_confirmation
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <ErrorMessage
                      name="password_confirmation"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <Row className="form-group">
                    <Col className="text-right">
                      <button
                        className="btn btn-light-login w-md waves-effect waves-light save-btn mr-2"
                        disabled={isSubmitting}
                        onClick={gotoPrevStep}
                        type="button"
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-login w-md waves-effect waves-light save-btn"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Reset
                        {isSubmitting && <Spinner className="spinner" />}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="mt-5 text-center">
          <p>
            Go back to{' '}
            <Link
              to="/study"
              className="font-weight-medium text-primary"
              onClick={handleClearState}
            >
              Study
            </Link>{' '}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ForgetPasswordPage;
