import React, { Component } from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { Table } from 'reactstrap';

/**
 * Imports specific from Laina Shared Platform
 */
import { DataRecord } from '@lainaedge/platformshared';

/**
 * Library to parse, validate, manipulate and display dates and times in JavaScript.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://momentjs.com/}
 * @packageDocumentation
 */
import moment from 'moment';

/**
 * Props for [[`LockHistoryItem`]] component
 */
export interface LockHistoryItemProps {
  records: DataRecord[];
}

/**
 * LockHistoryItem component
 *
 * @component LockHistoryItem
 * @category FormElements
 */
export default class LockHistoryItem extends Component<LockHistoryItemProps> {
  /**
   * Renders LockHistoryItem class component.
   */
  public render(): JSX.Element {
    return (
      <>
        <div className="table-responsive field-history-table">
          <label>Lock History</label>
          <Table className="table table-sm m-0">
            <thead>
              <tr>
                <th className="date">Date</th>
                <th className="user">User</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody>
              {this.props.records.map((record: DataRecord) => {
                return (
                  <tr key={record.id}>
                    <th className="date" scope="row">
                      {moment(record.data.event_dt_local).format('YYYY-MM-DD')}
                    </th>
                    <td className="user">{record.data.reported_by}</td>
                    <td>{record.data.event_text}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
