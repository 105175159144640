import React from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { InputGroup } from 'reactstrap';

import FormInput from './FormInput';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

/**
 * TextInput component
 *
 * @component TextInput
 * @category FormElements
 */
export default class TextInput extends FormInput {
  /**
   * Renders TextInput class component.
   */
  public render(): JSX.Element {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;
    return (
      <>
        <div>
          <InputGroup>
            <input
              className={
                this.props.formProps.errors[field.field]
                  ? 'is-invalid form-control'
                  : 'form-control'
              }
              type="text"
              data-testid="text-input"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onChange={(e: any) => {
                if (this.isEditFieldOnModal()) {
                  //this.props.formProps.handleChangeEditValue(e.target.value);
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else {
                  this.handleChangeText(field, e);
                }
              }}
              onBlur={() => {
                if (this.isEditFieldOnModal()) {
                  if (this.props.formProps.edit_values[field.field]) {
                    this.props.formProps.handleChangeEditValues(
                      field,
                      this.props.formProps.edit_values[field.field].trim(),
                    );
                  }
                } else {
                  this.handleTrimText(field);
                }
              }}
            />
            {this.renderUnit()}
          </InputGroup>
        </div>
        <div className="mt-1">{this.renderQnsAndUnavailableSwitches()}</div>
      </>
    );
  }
}
