import React from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

import packageJson from '../../package.json';

import { Link } from 'react-router-dom';

import { DataServices } from '@lainaedge/platformshared';

import { useData } from '../context/DataContext';
import { useAuth } from '../context/AuthContext';

// import images
import vector2 from '../assets/images/vector2.png';
import logo from '../assets/images/logo-laina.png';

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

DataServices.setEnvironment(
  (process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat') as Environment,
);
DataServices.setProjectName(
  process.env.REACT_APP_PROJECT_NAME ? process.env.REACT_APP_PROJECT_NAME : 'RB1',
);
DataServices.setServerAddress('');

const myProjectName = process.env.REACT_APP_PROJECT_NAME
  ? process.env.REACT_APP_PROJECT_NAME
  : 'RB1';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

type Props = {
  children: JSX.Element;
  showForgotPassword?: boolean;
};

/**
 * StudyNonAuthLayout component.
 *
 * @remarks
 * Layout for guest participants
 *
 * @component StudyNonAuthLayout
 * @category Component
 */
const StudyLayout = ({ children, showForgotPassword = false }: Props): JSX.Element => {
  const { versionInfo, dashTitle } = useData();
  const { error, clearState } = useAuth();

  const titleParts = dashTitle.split('(');

  const handleClearState = () => {
    clearState();
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p>{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="pt-4">
                  <div className="p-2">
                    {error && error ? <Alert color="danger">{error}</Alert> : null}
                    {children}
                    {myServerEnv === 'uat' && (
                      <div className="mt-3 login-page-small">
                        This is the User Acceptance Test (UAT) Environment. If you are at the site
                        or looking for production data &nbsp;
                        <a
                          className="login-notice"
                          href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                        >
                          Click Here.{' '}
                        </a>
                      </div>
                    )}

                    {myServerEnv === 'prod' && (
                      <div className="mt-3">This is the Production Server for {dashTitle}</div>
                    )}
                  </div>

                  {showForgotPassword && (
                    <div className="mt-4 text-center">
                      <Link
                        to="/study/forgotPassword/1"
                        className="text-muted"
                        onClick={handleClearState}
                      >
                        <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StudyLayout;
