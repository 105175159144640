import React from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';

import FormInput from './FormInput';

/**
 * A lightweight library that converts raw HTML to a React DOM structure.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://www.npmjs.com/package/html-to-react}
 * or {@link https://github.com/aknuds1/html-to-react}
 * @packageDocumentation
 */
const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * MultiSelect component
 *
 * @component MultiSelect
 * @category FormElements
 */
export default class MultiSelect extends FormInput {
  /**
   * Used to change the value of a multiple select field.
   *
   * @remarks
   * If the current field value matches the new value then it sets state
   * and form value without it, otherwise adds it to the current value.
   *
   * @param field - Parser to the field.
   * @param e - New value to add or remove.
   * @returns Void
   */
  handleChangeRadio = (field: StepForm.FieldInfo, e: any) => {
    const step = this.props.formProps.step;

    const myValueDatabase = step.getValueDatabase(field.field);

    const valueArray = myValueDatabase.split(',').filter((v: any) => {
      return v !== '';
    });
    const newValue = valueArray.includes(e.target.value)
      ? valueArray.filter((v: any) => {
          return v !== e.target.value;
        })
      : [...valueArray, e.target.value];

    this.setState({ myFieldValue: newValue.join(',') });
    this.props.formProps.step.setValueFromUser(field.field, newValue.join(','));
  };

  /**
   * Renders MultiSelect class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;
    // const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;
    //printEDCLog('lookup input for ' + field.field, this.state.myFieldValue);

    if (step.tableDef && step.dataDictionary) {
      const name = step.tableDef.getFieldLookupName(field.field);
      const list = step.dataDictionary.getLookupTable(name);

      const myValueDatabase = step.getValueDatabase(field.field);

      const valueArray = myValueDatabase.split(',');

      // printEDCLog('myValueDatabase', myValueDatabase, valueArray);

      return (
        <>
          {list
            ? list.map((item: any) => (
                <div className="custom-control custom-checkbox mb-3" key={item.code}>
                  <input
                    type="checkbox"
                    id={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field + item.code
                        : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                    }
                    className="custom-control-input"
                    name={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field
                        : this.isEditMode()
                        ? 'm' + field.field
                        : field.field
                    }
                    value={item.code}
                    checked={
                      this.isEditFieldOnModal()
                        ? this.props.formProps.edit_values[field.field].includes(item.code)
                        : this.props.formProps.hide_fields[field.field]
                        ? false
                        : valueArray.includes(item.code)
                    }
                    disabled={this.isFieldDisabled()}
                    onChange={(e: any) => {
                      if (this.isEditFieldOnModal()) {
                        this.props.formProps.handleChangeEditValues(field, e.target.value);
                      } else {
                        this.handleChangeRadio(field, e);
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field + item.code
                        : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                    }
                  >
                    {htmlToReactParser.parse(item.english)}
                  </label>
                </div>
              ))
            : ''}
          {this.renderQnsAndUnavailableSwitches()}
        </>
      );
    }

    return <></>;
  }
}
