import React from 'react';
import { Cookies } from 'react-cookie';
import { Modal } from 'reactstrap';

import { useData } from '../context/DataContext';

const cookies = new Cookies();

/**
 * GearModal component.
 *
 * @remarks
 * Renders table that lists LogicEngine Key & Values
 *
 * @component GearModal
 * @category Component
 */
const GearModal = () => {
  const { showGearModal, setShowGearModal, enableTrace, setEnableTrace, logicValues } = useData();

  const copyToClipboard = (text: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text);
    }
  };

  return (
    <Modal size="lg" isOpen={showGearModal}>
      <div className="modal-header">
        <h2 className="modal-title mt-0">LogicEngine Values</h2>
        <button
          type="button"
          onClick={(): void => setShowGearModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <div className="custom-control custom-checkbox mb-3 text-right">
          <input
            type="checkbox"
            id="enable-trace"
            className="custom-control-input"
            name="enable-trace"
            checked={enableTrace}
            onChange={(e) => {
              if (e.target.checked) {
                cookies.set('enableTrace', e.target.checked, { path: '/' });
              } else {
                cookies.remove('enableTrace', { path: '/' });
              }
              setEnableTrace(e.target.checked);
            }}
          />
          <label className="custom-control-label" htmlFor="enable-trace">
            Enable Trace
          </label>
        </div>
        <table className="table sortable-table table-dark table-striped gear-table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {logicValues?.map((item: any) => {
              const value = item.value;
              return (
                <tr key={item.key}>
                  <td className="copy-cell" onClick={() => copyToClipboard(item.key)}>
                    {item.key}
                  </td>
                  <td>{value != null ? value.toString() : 'null'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default GearModal;
