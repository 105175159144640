import React, { useEffect, useState } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { RemoteAgent, RemoteAgentConnectionType } from '@lainaedge/platformshared';
//import { RemoteAgentPageStatus, RemoteAgentPageUpdateCallback } from '@lainaedge/platformshared/src/RemoteAgent/RemoteAgent';

import { printEDCLog } from '../helpers';

const test_pid = '9994-424-317';
const test_password = 'laina123';
const env = 'uat';
const project = 'rb2';

const remote = RemoteAgent.instance();

/**
 * TrialWatch component.
 *
 * @remarks
 * TrialWatch that runs remoteAgent
 *
 * @component TrialWatch
 * @category Page
 */
const TrialWatch = (): JSX.Element => {
  const [status, setStatus] = useState('waiting_for_device');
  const [logs, setLogs] = useState<Array<RemoteAgentConnectionType>>([]);

  /** When a session is found that we want to debug, send the login. */
  // eslint-disable-next-line
  async function startSession(conn: RemoteAgentConnectionType) {
    printEDCLog('DemoRemoteAgentSend: Sending login request as participant', test_pid);

    await remote.sendCommandEnv(conn.session_id, env, project);
    await remote.sendCommandLoginParticipant(conn.session_id, test_pid, test_password);

    // The remote should be at the dashboard or whatever page is loaded after login.
    setStatus('complete');

    return true;
  }

  useEffect(() => {
    //remote.setStatus("DebugDemo");

    /** Initialize the remote agent connection and watch for announcements from connected
	clients.  Once a client updates or connects it will send a ping.*/
    remote.startRemoteAgent();

    //remote.setStatus('DebugDemo');

    // Set handler for incoming ping information
    remote.cbPing = (conn: RemoteAgentConnectionType) => {
      printEDCLog('Received info on:', conn);

      console.log(logs);
      const found = logs.find((log) => log.session_id === conn.session_id);
      console.log('found', found);
      if (found) {
        setLogs((oldLogs) => {
          return oldLogs.map((log) => {
            return log.session_id === conn.session_id ? { ...log, ...conn } : log;
          });
        });
      } else {
        setLogs((oldLogs) => [...oldLogs, conn]);
      }

      if (status === 'waiting_for_device') {
        printEDCLog('Found session to debug, session id = ', conn.session_id);

        // Start a session using this connection for debugging
        // start by sending login information as a participant
        // startSession(conn).then(()=> {
        // 	setStatus('complete2');
        // });
      } else {
        printEDCLog('Not a match, status=', status);
      }
    };

    // Set handler for page updates
    remote.cbPageUpdate = (update: any) => {
      printEDCLog('PAGE STATUS:', update);
    };

    // Initialize reader to listen
    remote.watchForConnections();

    // Ask any clients currently running to ping
    remote.sendHeartbeatRequest();

    // const t = setInterval(() => {
    //     printEDCLog("Timer");
    //     remote.sendHeartbeatRequest();

    //     printEDCLog('status', status);
    //     if (status === 'complete')
    //     {
    // 		printEDCLog('clear call');
    //         clearInterval(t);
    //     }
    // }, 5000);

    // return () => {
    // 	clearInterval(t);
    // }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <table className="table sortable-table">
            <thead>
              <tr>
                <td>device_id</td>
                <td>env</td>
                <td>project</td>
                <td>ip</td>
                <td>pid</td>
                <td>page</td>
                <td>status</td>
              </tr>
            </thead>
            <tbody>
              {logs.map((log: RemoteAgentConnectionType, index: number) => {
                return (
                  <tr key={'log' + index}>
                    <td>{log.device_id}</td>
                    <td>{log.env}</td>
                    <td>{log.project}</td>
                    <td>{log.ip}</td>
                    <td>{log.pid}</td>
                    <td>{log.page}</td>
                    <td>{log.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrialWatch;
