import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepDashStat } from '@lainaedge/platformshared';

/**
 * Visual primitives for the component age. Use the best bits of ES6 and CSS to style your apps without stress
 *
 * @remarks
 *
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import styled from 'styled-components';

import { RenderHelper } from '../RenderHelper';

const DashStat = styled.div`
  display: inline-block;
  word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  min-height: 1px;
  padding: 0.25rem;
  margin-bottom: 24px;
  //box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);

  text-align: center;

  div {
    background-color: #fff;
    padding: 1rem;
    cursor: pointer;
  }
`;

/**
 * Props for [[`PageDashStat`]] component
 */
export interface PageDashStatProps {
  /** Used run processTextReplacement. */
  logic: any;
  /** Step data */
  step: StepDashStat;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageDashStat component
 *
 * @component PageDashStat
 * @category PageElements
 */
export default class PageDashStat extends Component<PageDashStatProps> {
  constructor(props: PageDashStatProps) {
    super(props);

    this.state = {};
  }

  /**
   * DashStat click handler. Redirects to option 1 of the step data.
   */
  handleClick = async () => {
    const step = this.props.step;
    //window.location.href = step.raw.option_1!;
    this.props.renderer.navigate(step.raw.option_1);
  };

  /**
   * Renders PageDashStat class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;
    const processedText = this.props.logic.processTextReplacement(step.text);
    return (
      <DashStat className="mini-stats-wid col-6 col-md-4 col-lg-3" onClick={this.handleClick}>
        <div>
          <p className="text-muted font-weight-medium">{processedText}</p>
          <h4 className="mb-0">{step.count}</h4>
        </div>
      </DashStat>
    );
  }
}
