import React from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';

/**
 * Library to parse, validate, manipulate and display dates and times in JavaScript.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://momentjs.com/}
 * @packageDocumentation
 */
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, InputGroup, InputGroupAddon } from 'reactstrap';

import FormInput from './FormInput';

import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

/**
 * DateInput component @extends FormInput
 *
 * @component DateInput
 * @category FormElements
 */

function getYear(d: Date) {
  return moment(d).year();
}

function getMonth(d: Date) {
  return moment(d).month();
}

function range(start: number, end: number) {
  return [...Array(end - start + 1).keys()].map((i) => i + start);
}
export default class DateInput extends FormInput {
  /**
   * Used to set the value of a field.
   *
   * @param field - Points to the field.
   * @param date  - Used to set the value.
   * @returns Void
   */
  handleChangeDate = (field: StepForm.FieldInfo, date: Date) => {
    //this.debugMessage(field, date);
    if (date) {
      this.setValue(field, moment(date).format('YYYY-MM-DD'));
      // this.props.formProps.step.setValueFromUser(field.field, date.toUTCString());
      // this.setState({ [field.field]: date.toUTCString() });
    } else {
      this.setValue(field, '');
      // this.props.formProps.step.setValueFromUser(field.field, '');
      // this.setState({ [field.field]: '' });
    }
  };

  /**
   * Renders DateInput class component.
   */
  public render(): JSX.Element {
    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const years = range(1900, getYear(new Date()) + 1);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    return (
      <>
        <div className="d-inline-block date-input-field mr-2 mb-1">
          <InputGroup className="custom-date-picker">
            <InputGroupAddon addonType="prepend">
              <DatePicker
                className="form-control"
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      className="btn btn-xs btn-primary"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                      {years.map((option: any) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      className="btn btn-xs btn-primary"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
                name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
                selected={
                  this.isEditFieldOnModal()
                    ? moment(this.props.formProps.edit_values[field.field]).isValid()
                      ? moment(this.props.formProps.edit_values[field.field]).toDate()
                      : undefined
                    : moment(this.state.myFieldValue).isValid()
                    ? moment(this.state.myFieldValue).toDate()
                    : undefined
                }
                disabled={this.isFieldDisabled()}
                onChange={(e: any) => {
                  if (this.isEditFieldOnModal()) {
                    this.props.formProps.handleChangeEditValues(
                      field,
                      e ? moment(e).format('YYYY-MM-DD') : '',
                    );
                  } else {
                    this.handleChangeDate(field, e);
                  }
                }}
                customInput={
                  <Button color="primary" data-toggle>
                    <i className="fa fa-calendar-alt bx" />
                  </Button>
                }
              />
            </InputGroupAddon>
            <input
              className={
                this.props.formProps.errors[field.field]
                  ? 'is-invalid form-control'
                  : 'form-control'
              }
              type="text"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onChange={(e: any) => {
                const value = e.target.value;
                const re = /^[0-9\.\-]*$/;
                if (value !== '' && !re.test(value)) {
                  return;
                }
                if (this.isEditFieldOnModal()) {
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else {
                  this.handleChangeText(field, e);
                }
              }}
              onBlur={() => {
                if (this.isEditFieldOnModal()) {
                  if (this.props.formProps.edit_values[field.field]) {
                    this.props.formProps.handleChangeEditValues(
                      field,
                      this.props.formProps.edit_values[field.field].trim(),
                    );
                  }
                } else {
                  this.handleTrimText(field);
                }
              }}
              placeholder="yyyy-mm-dd"
            />
          </InputGroup>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
