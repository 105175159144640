import React from 'react';
// context
import { useData } from '../../context/DataContext';

const StudyHeader = () => {
  const { pageTitle } = useData();
  return (
    <React.Fragment>
      <header id="study-topbar" className="participant-header">
        <p>{pageTitle}</p>
      </header>
    </React.Fragment>
  );
};

export default StudyHeader;
