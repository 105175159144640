import React from 'react';

// Import menuDropdown
// import LanguageDropdown from '../Common/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../Common/TopbarDropdown/ProfileMenu';

import { useData } from '../../context/DataContext';
import { ReactComponent as BarsIcon } from '../../assets/images/icons/burger.svg';
import { ReactComponent as GearIcon } from '../../assets/images/icons/gear.svg';

const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

const Header = () => {
  const { leftSideBarType, setLeftSideBarType, showGearModal, setShowGearModal } = useData();

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const tToggle = () => {
    if (leftSideBarType === 'default') {
      setLeftSideBarType('menu-closed');
    } else if (['condensed', 'menu-opened', 'menu-closed'].includes(leftSideBarType)) {
      setLeftSideBarType('default');
    }
  };

  const toggleLogicValuesModal = () => {
    setShowGearModal(!showGearModal);
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="ml-3 btn btn-sm font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <BarsIcon />
            </button>
            <button
              type="button"
              onClick={() => {
                toggleLogicValuesModal();
              }}
              className="ml-1 btn btn-sm font-size-24 header-item waves-effect"
              id="gear-btn"
            >
              <GearIcon />
            </button>
            {myServerEnv == 'uat' && (
              <div className="p-2 d-flex justify-content-center align-items-center text-white cursor-pointer">
                UAT
              </div>
            )}
          </div>
          <div className="d-flex">
            {/* <LanguageDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
