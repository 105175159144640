import React from 'react';
import FormInput from './FormInput';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';
import { InputProps } from './types';

/**
 * Dropdown component @extends FormInput
 *
 * @component Dropdown
 * @category FormElements
 */
export default class Dropdown extends FormInput {
  constructor(props: InputProps) {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
    };
  }

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelect = (e: any) => {
    const field = this.props.formProps.field;
    console.log(e.target.value);

    if (this.isEditFieldOnModal()) {
      this.props.formProps.handleChangeEditValues(field, e.target.value);
    } else {
      this.handleChangeText(field, e);
    }
  };

  /**
   * Renders Dropdown class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;
    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    let options: Array<any> = [];
    if (step.tableDef && step.dataDictionary) {
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents a lookup table */
      const list = step.dataDictionary.getLookupTable(name);

      /** Represents a value of a field as it would be stored in the database. */
      // const myValueDatabase = step.getValueDatabase(field.field);

      options = list;
    }
    // console.log(options);
    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <select
            className="form-control drop-down"
            name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
            onChange={this.handleSelect}
            disabled={this.isFieldDisabled()}
            value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
          >
            <option value="">Select</option>
            {options.map((item: any) => (
              <option key={item.code} value={item.code}>
                {item.english}
              </option>
            ))}
          </select>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
