import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm, StringOptions } from '@lainaedge/platformshared';

import { InputProps, InputState } from './types';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

/**
 * FormInput component
 *
 * @component FormInput
 * @category FormElements
 */
export default class FormInput extends Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
    };
  }

  async componentDidUpdate(prevProps: InputProps) {
    const field = this.props.formProps.field;

    if (
      this.props.formProps.counter !== prevProps.formProps.counter ||
      this.props.formProps.field.field !== prevProps.formProps.field.field
    ) {
      this.setState({ myFieldValue: this.getValue(field) });
    }
  }

  /** Get the value from the record using getValueDatabase and return a clean/trimmed version */
  getValue = (field: StepForm.FieldInfo): string => {
    const myValueDatabase = this.props.formProps.step.getValueDatabase(field.field);

    let value = '';
    if (myValueDatabase !== undefined && myValueDatabase !== null) {
      if (typeof myValueDatabase === 'string') value = myValueDatabase.trim();
      else if (myValueDatabase.toString) value = myValueDatabase.toString();
      else value = myValueDatabase;
    }
    return value;
  };

  /** Set the value that should appear in the editor to the string 'newValue' and
   * set the form value at the same time.  Skips setting the form value if the user
   * enters something ending in "." so that 0. or .03 or something similar can be entered.
   */
  setValue = (field: StepForm.FieldInfo, newValue: string) => {
    this.setState({ myFieldValue: newValue });

    if (newValue.length > 0) {
      if (newValue.charAt(newValue.length - 1) === '.') return;
    }

    this.props.formProps.step.setValueFromUser(field.field, newValue);
  };

  /**
   * Returns true if edit field is on modal and is not disabled.
   *
   * @returns
   * Boolean
   */
  isEditFieldOnModal = () => {
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    return is_on_modal && !is_disabled;
  };

  /**
   * Returns true if edit mode is true and if is on modal is false
   *
   * @returns
   * Boolean
   */
  isEditMode = () => {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;

    return !is_on_modal && step.is_edit_mode;
  };

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeText = (field: StepForm.FieldInfo, e: any) => {
    const value = e.target.value;
    this.setValue(field, value);
  };

  /**
   * Cleans/trims value of a provided field and sets it as the new value.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  handleTrimText = (field: StepForm.FieldInfo) => {
    const value = this.getValue(field);
    this.setValue(field, value);
  };

  /**
   * Used to update value based on QNS Switch
   *
   * @returns Void
   */
  handleToggleQNS = () => {
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    if (fieldValue == QNS_VALUE) {
      this.setValue(field, '');
    } else {
      this.setValue(field, QNS_VALUE);
    }
  };

  /**
   * Used to update value based on UNAVAILABLE Switch
   *
   * @returns Void
   */
  handleToggleUnavailable = () => {
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    if (fieldValue == UNAVAILABLE_VALUE) {
      this.setValue(field, '');
    } else {
      this.setValue(field, UNAVAILABLE_VALUE);
    }
  };

  /**
   * Renders units of the field field.
   */
  renderUnit = (): JSX.Element => {
    if (this.state.myUnits) {
      return (
        <span className="input-group-append">
          <span className="input-group-text">
            <i>{this.state.myUnits}</i>
          </span>
        </span>
      );
    }
    return <></>;
  };

  /**
   * Returns true if edit mode is true and if is on modal is false
   *
   * @returns
   * Boolean
   */
  isFieldDisabled = () => {
    const is_disabled = this.props.formProps.is_disabled;
    const field = this.props.formProps.field;
    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    const isFieldDisabled =
      this.isEditMode() ||
      is_disabled ||
      this.props.formProps.hide_fields[field.field] ||
      this.props.formProps.field.enabled === false ||
      [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue);

    return isFieldDisabled;
  };

  /**
   * Renders QNS and Unavailable switches
   */
  renderQnsAndUnavailableSwitches = (): JSX.Element => {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;
    const step = this.props.formProps.step;

    const fieldDetails = step.tableDef?.getField(field.field);
    const fieldOptions = new StringOptions(fieldDetails?.options);

    const showQns = fieldOptions.checkOption('qns');
    const showUnavailable = fieldOptions.checkOption('unavailable');

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    return (
      <>
        {showQns && (
          <div className="d-inline-block mr-3 mb-1 custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={fieldValue == QNS_VALUE}
              onChange={this.handleToggleQNS}
            />
            <label className="custom-control-label" onClick={this.handleToggleQNS}>
              Quantity not sufficient
            </label>
          </div>
        )}
        {showUnavailable && (
          <div className="d-inline-block mr-3 custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={fieldValue == UNAVAILABLE_VALUE}
              onChange={this.handleToggleUnavailable}
            />
            <label className="custom-control-label" onClick={this.handleToggleUnavailable}>
              Unavailable
            </label>
          </div>
        )}
      </>
    );
  };

  /**
   * Renders FormInput class component.
   */
  public render(): JSX.Element {
    return <>{this.props.children}</>;
  }
}
