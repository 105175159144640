import React, { Component } from 'react';
import { DataTools } from '@lainaedge/platformshared';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepText } from '@lainaedge/platformshared';
import { CardTitle, CardText, UncontrolledAlert } from 'reactstrap';
import { DataContext } from '../../context/DataContext';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * Props for [[`PageText`]] component
 */
export interface PageTextProps {
  /** Used for displaying replaced text. */
  logic: any;
  /** Step data. */
  step: StepText;
}

/**
 * PageText component
 *
 * @component PageText
 * @category PageElements
 */
export default class PageText extends Component<PageTextProps> {
  static contextType = DataContext;

  constructor(props: PageTextProps) {
    super(props);
    this.state = {};
  }

  /**
   * Renders PageText class component.
   * @see demo page /page/text_styles in demo study.
   */
  public render(): JSX.Element {
    const step = this.props.step;

    // Allow for additional Bootstrap modifiers
    const additionalClassNames: string[] = [];

    // Reverse the flow of boxes??
    if (step.options.checkOption('Reverse')) additionalClassNames.push('flex-box-reverse');

    /* Changes to background color */
    if (step.options.checkOption('Info')) additionalClassNames.push('bg-info text-light pl-2');
    if (step.options.checkOption('Primary'))
      additionalClassNames.push('bg-primary text-light pl-2');
    if (step.options.checkOption('dark')) additionalClassNames.push('bg-dark text-light pl-2');

    /* Highlight the current row, meant to be used with Row */
    if (step.options.checkOption('highlight')) additionalClassNames.push('bd-highlight pl-2');

    /* Add borders to the element */
    if (step.options.checkOption('borderprimary'))
      additionalClassNames.push('border border-primary');
    if (step.options.checkOption('borderseconday'))
      additionalClassNames.push('border border-secondary');
    if (step.options.checkOption('bordersuccess'))
      additionalClassNames.push('border border-success');
    if (step.options.checkOption('borderdanger')) additionalClassNames.push('border border-danger');
    if (step.options.checkOption('borderwarning'))
      additionalClassNames.push('border border-warning');
    if (step.options.checkOption('BorderInfo')) additionalClassNames.push('border border-info');
    if (step.options.checkOption('BorderLight')) additionalClassNames.push('border border-light');
    if (step.options.checkOption('BorderDark')) additionalClassNames.push('border border-dark');

    /** Add padding and margin options */
    if (step.options.checkOption('p1')) additionalClassNames.push('p1 p-1');
    if (step.options.checkOption('p2')) additionalClassNames.push('p2 p-2');
    if (step.options.checkOption('p3')) additionalClassNames.push('p3 p-3');
    if (step.options.checkOption('p4')) additionalClassNames.push('p4 p-4');

    if (step.options.checkOption('mb1')) additionalClassNames.push('mb1 mb-1');
    if (step.options.checkOption('mb2')) additionalClassNames.push('mb2 mb-2');
    if (step.options.checkOption('mb3')) additionalClassNames.push('mb3 mb-3');
    if (step.options.checkOption('mb4')) additionalClassNames.push('mb4 mb-4');

    if (step.options.checkOption('mt1')) additionalClassNames.push('mt1 mt-1');
    if (step.options.checkOption('mt2')) additionalClassNames.push('mt2 mt-2');
    if (step.options.checkOption('mt3')) additionalClassNames.push('mt3 mt-3');
    if (step.options.checkOption('mt4')) additionalClassNames.push('mt4 mt-4');

    /** Codify the additional options to add to other text */
    const additionalClasses: string = additionalClassNames.join(' ');

    if (step.options.checkOption('SectionTitle'))
      return (
        <div className={`mt-1 mb-0 card-title textSectionTitle ${additionalClasses}`}>
          {step.text}
        </div>
      );

    if (step.options.checkOption('Title'))
      return <h4 className={`${additionalClasses}`}>{step.text}</h4>;

    if (step.options.checkOption('SubTitle'))
      return <h5 className={`${additionalClasses}`}>{step.text}</h5>;

    if (step.options.checkOption('Hint'))
      return <div className={`mt-0 mb-1 text-info ${additionalClasses}`}>{step.text}</div>;

    if (step.options.checkOption('HintLine'))
      return (
        <div className={`mt-2 mb-0 text-info border-bottom ${additionalClasses}`}>{step.text}</div>
      );

    if (step.options.checkOption('Footer')) {
      this.context?.setFooterText(step.text);
      //const footerText = this.props.logic.processTextReplacement(step.option_1);
      return <></>;
    }

    if (step.options.checkOption('CardTitle'))
      return <CardTitle className={`mt-0 ${additionalClasses}`}>{step.text}</CardTitle>;

    if (step.options.checkOption('CardText'))
      return <CardText className={`${additionalClasses}`}>{step.text}</CardText>;

    if (step.options.checkOption('TaskTitle'))
      return <h5 className={`text-truncate font-size-18 ${additionalClasses}`}>{step.text}</h5>;

    if (step.options.checkOption('TaskIcon'))
      return (
        <span>
          <i className={step.text} />
        </span>
      );

    if (step.options.checkOption('TaskSubTitle'))
      return <p className={`text-muted mb-0 ${additionalClasses}`}>{step.text}</p>;

    if (step.options.checkOption('TaskStatus')) return <span>Status : {step.text}</span>;

    if (step.options.checkOption('AlertDanger'))
      return (
        <UncontrolledAlert color="danger" role="alert">
          <span className={additionalClasses}>{step.text}</span>
        </UncontrolledAlert>
      );

    if (step.options.checkOption('AlertSuccess'))
      return (
        <UncontrolledAlert color="success" role="alert">
          <span className={additionalClasses}>{step.text}</span>
        </UncontrolledAlert>
      );

    if (step.options.checkOption('AlertPrimary'))
      return (
        <UncontrolledAlert color="primary" role="alert">
          <span className={additionalClasses}>{step.text}</span>
        </UncontrolledAlert>
      );

    if (step.options.checkOption('AlertWarning'))
      return (
        <UncontrolledAlert color="warning" role="alert">
          <span className={additionalClasses}>{step.text}</span>
        </UncontrolledAlert>
      );

    if (step.options.checkOption('BulletItem'))
      return (
        <p className="text-muted">
          <i className="fa fa-caret-right font-size-16 align-middle text-primary mr-2"></i>
          {step.text}
        </p>
      );

    if (step.options.checkOption('Row'))
      // Brian: This option allows a type of table to be created for showing admin data
      // option_1 and option_2 are put into columns that can align.
      return (
        <div className={`d-md-flex flex-row ${additionalClasses}`}>
          <div className="pt-2"> {step.text} </div>
        </div>
      );

    if (step.options.checkOption('Panel')) {
      // Brian: This option allows a type of panel to be created
      // @see https://www.w3schools.com/bootstrap/bootstrap_panels.asp
      // option_1 is the panel title (optionally)
      // text_en is the panel text
      let panHeading = <></>;
      if (DataTools.internalValidateString(step.option_1) != '') {
        panHeading = (
          <div className="panel-heading">
            {this.props.logic.processTextReplacement(step.option_1)}
          </div>
        );
      }

      return (
        <div className={`panel panel-default ${additionalClasses}`}>
          {panHeading}
          <div className="panel-body">{this.props.logic.processTextReplacement(step.text)}</div>
        </div>
      );
    }

    if (step.options.checkOption('WithLabel'))
      // Brian: This option allows a type of table to be created for showing admin data
      // option_1 and option_2 are put into columns that can align.
      return (
        <div className={`d-md-flex flex-row ${additionalClasses}`}>
          <div className="pt-2 w-25"> {step.text} </div>
          <div className="p-2 w-25"> {this.props.logic.processTextReplacement(step.option_1)} </div>
          <div className="p-2 w-25"> {this.props.logic.processTextReplacement(step.option_2)} </div>
        </div>
      );

    return <p className={additionalClasses}>{htmlToReactParser.parse(step.text)}</p>;
  }
}
