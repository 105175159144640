import React, { useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';

// Layout Related Components
import Footer from './AuthLayout/Footer';
import StudyHeader from './AuthLayout/StudyHeader';

import { useAuth } from '../context/AuthContext';

const Layout = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/') {
      // printEDCLog('save redirectUrl when url entered on browser', location.pathname);
    }
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <StudyHeader></StudyHeader>
        <div className="participant-content">
          <Outlet />
        </div>
        <Footer type={'participant'} />
      </div>
    </React.Fragment>
  );
};

export default Layout;
