import React from 'react';

import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { InputGroup } from 'reactstrap';

import FormInput from './FormInput';

/**
 * NumberInput component
 *
 * @component NumberInput
 * @category FormElements
 */
export default class NumberInput extends FormInput {
  /**
   * Renders NumberInput class component.
   */
  public render(): JSX.Element {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;
    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <InputGroup>
            <input
              className={
                this.props.formProps.errors[field.field]
                  ? 'is-invalid form-control'
                  : 'form-control'
              }
              type="number"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onChange={(e: any) => {
                if (this.isEditFieldOnModal()) {
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else {
                  this.handleChangeText(field, e);
                }
              }}
            />
          </InputGroup>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
