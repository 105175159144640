import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MenuItem } from '../../constants/types';
import SidebarMenuLink from './SidebarMenuLink';
import SidebarMenu from './SidebarMenu';

import { useData } from '../../context/DataContext';

type IntlTitleProps = 'title_en';

const SidebarContent = () => {
  const {
    config: c,
    lang,
    leftSideBarType,
    logo,
    logoSm,
    menuUpdated,
    setLeftSideBarType,
    setMenuUpdate,
  } = useData();
  const config: any = c;
  const logoImage = require('../../assets/images/' + logo);
  const logoSmImage = require('../../assets/images/' + logoSm);
  const location = useLocation();

  const [openedMenu, setOpenedMenu] = useState<MenuItem | undefined>(undefined);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  function getTitlePropName(lang: string): IntlTitleProps {
    return lang === 'en' ? 'title_en' : 'title_en';
  }

  useEffect(() => {
    if (['condensed', 'menu-opened', 'menu-closed'].includes(leftSideBarType)) {
      if (!!openedMenu) {
        setLeftSideBarType('menu-opened');
      } else {
        setLeftSideBarType('menu-closed');
      }
    }
  }, [openedMenu]);

  useEffect(() => {
    if (openedMenu && leftSideBarType === 'menu-closed') {
      setOpenedMenu(undefined);
    }
  }, [leftSideBarType]);

  useEffect(() => {
    const getAllParticipants = async () => {
      if (!menuUpdated) {
        if (menuItems && menuItems.length) return;
      }

      if (!config || !config.Sidebar) {
        setMenuItems([]);
        return;
      }

      // for (const item of config.Sidebar) {
      //   if (item.badge_query && /;/.test(item.badge_query)) {
      //     // const result = await dataQuery.executeCountQueryByText(item.badge_query);
      //     // item.badge_count = result;
      //   }
      // }

      let level = 0;
      let index = 0;
      let in_submenu = 0;
      const menu: MenuItem[] = [];

      config.Sidebar.forEach((item: any) => {
        const itemType = item.item_type || 'Link';
        if (level === 0 && itemType === 'Link') {
          in_submenu = 0;
          menu.push({
            ...item,
            children: [],
          });
          index++;
        } else if (itemType.startsWith('Menu')) {
          if (in_submenu === 1) index++;
          in_submenu = 1;

          level = 1;
          menu.push({
            ...item,
            children: [],
          });
        } else if (itemType === 'Link' && level === 1) {
          if (!item.route) item.route = '/page/screenfailed';

          menu[index].children.push({
            ...item,
            children: [],
            badge_count: item.badge_count,
          });

          // Set parent menu open when we are on its sub page
          if (item.route == location.pathname) {
            setOpenedMenu(menu[index]);
          }
          menu[index].is_active = true;
        }
      });

      setMenuItems(menu);
      setMenuUpdate(false);
    };

    if (!mounted.current) return;
    const ac = new AbortController();
    getAllParticipants().then();

    return () => {
      ac.abort();
    };
  }, [config, menuUpdated, setMenuUpdate]);

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSmImage} alt="" height="44" />
            </span>
            <span className="logo-lg">
              <img src={logoImage} alt="" height="60" />
            </span>
          </Link>
        </div>
        <ul className="metismenu list-unstyled mm-active" id="side-menu">
          {menuItems.map((item, i) =>
            item.item_type === 'Link' ? (
              <SidebarMenuLink
                key={i}
                link={item}
                label={item[getTitlePropName(lang)] || ''}
                index={`${i}-0`}
              />
            ) : (
              <SidebarMenu
                key={i}
                menu={item}
                index={i}
                collapse={item !== openedMenu}
                onCollapse={(menu) => setOpenedMenu(menu)}
              />
            ),
          )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
