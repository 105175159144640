import React, { useState } from 'react';
import { Modal, Form, InputGroup } from 'reactstrap';
import moment from 'moment';
import { DataRecord } from '@lainaedge/platformshared';

/**
 * Props for [[`QueryReplyModal`]] component
 */
interface ModalProps {
  isShowQueryReplyModal: boolean | undefined;
  is_query_mode: boolean;
  is_edit_mode: boolean;
  handleQueryReplySave: (message: string) => void;
  toggleQueryReplyModal: () => void;
  query_record: DataRecord<any> | undefined;
  error: string;
}

/**
 * QueryReplyModal component
 *
 * @component QueryReplyModal
 * @category Component
 */
const QueryReplyModal = ({
  isShowQueryReplyModal,
  is_query_mode,
  is_edit_mode,
  handleQueryReplySave,
  toggleQueryReplyModal,
  query_record,
  error,
}: ModalProps): JSX.Element => {
  const [message, setMessage] = useState<string>('');

  return (
    <Modal size="lg" isOpen={isShowQueryReplyModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{is_query_mode ? 'Query Reply Review' : 'Query Reply'}</h5>
        <button
          type="button"
          onClick={(): void => toggleQueryReplyModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {query_record && (
          <div id="query-form" className="twitter-bs-wizard">
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
              }}
            >
              <div className="col-md-12">
                <label className={'col-md-12 col-form-label'}>
                  Query submitted by {query_record.data.reported_by} on{' '}
                  {moment(query_record.data.event_dt_local).format('DD MMMM YYYY')} at{' '}
                  {moment(query_record.data.event_dt_local).format('h:m a')}:
                </label>
                <div className="col-md-12">{query_record.data.message}</div>
                {is_query_mode && (
                  <>
                    <label className={'col-md-12 col-form-label'}>
                      Query reply from {query_record.data.response_user} on{' '}
                      {moment(query_record.data.event_dt_local).format('DD MMMM YYYY')} at{' '}
                      {moment(query_record.data.event_dt_local).format('h:m a')}:
                    </label>
                    <div className="col-md-12">{query_record.data.response}</div>
                  </>
                )}
                <label className={'col-md-12 col-form-label'}>
                  {is_edit_mode && 'Submit a response'}
                  {is_query_mode && 'Reply back to this query'}:
                </label>
                <div className="col-md-12">
                  <InputGroup>
                    <input
                      className={'form-control'}
                      name={'message'}
                      value={message}
                      type="text"
                      onChange={(e: any): void => {
                        setMessage(e.target.value);
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-12">
                  {error && <span className="validation-error">{error}</span>}
                </div>
              </div>
            </Form>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={(): void => handleQueryReplySave(message.trim())}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};
export default QueryReplyModal;
