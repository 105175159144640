import React, { Component } from 'react';

import LockSwitch from './TableElements/LockSwitch';
/**
 * Imports specific from Laina Shared Platform
 */
import { DataRecord, LogicEngine } from '@lainaedge/platformshared';
import { LockManager } from '@lainaedge/platformshared/lib/LockManager/LockManager';

/**
 * toastr is a Javascript library for non-blocking notifications.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://www.npmjs.com/package/toastr}
 * @packageDocumentation
 */
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepTable } from '@lainaedge/platformshared';
import { Link } from 'react-router-dom';
window.React = require('react');

import ascImg from '../../assets/images/sort-asc.svg';
import descImg from '../../assets/images/sort-desc.svg';
import sortImg from '../../assets/images/sort.svg';
/**
 * Props for [[`PageTable`]] component
 */
export interface PageTableProps {
  /** Step data.*/
  step: StepTable;
}

/**
 * Used for dynamic key value objects.
 */
export interface LooseObject {
  [key: string]: any;
}

/**
 * Used for state for [[`PageTable`]] component
 */
export interface PageTableState extends LooseObject {
  /** Represents a field used for sort */
  sortKey: string;
  sortKeyIndex: number;
  /** Represents the sort direction  */
  sortDirection: string;
  tableData: CellValue[][];
}

/**
 * Used in [[`PageTable`]] component
 */
export interface CellValue {
  sortValue: any;
  /** String value representing text of the cell. */
  text: string;
  /** Optional, path for redirection. */
  link?: string;
  /** Optional, cell icon. */
  icon?: string;
  /** Cell alignment. */
  align: string;
}

/**
 * PageTable component
 *
 * @component PageTable
 * @category PageElements
 */
export class PageTable extends Component<PageTableProps> {
  /**
   * Uses [[`PageFormState`]] interface as state object.
   */
  state: PageTableState = {
    sortKey: '',
    sortKeyIndex: -1,
    sortDirection: '',
    tableData: this.props.step.getFormattedData(),
  };

  // constructor(props: PageTableProps) {
  //   super(props);
  // }

  /* Return a valid column alignment if one is passed in */
  getAlign(rawAlign: string): 'left' | 'right' | 'center' | 'justify' {
    if (rawAlign && rawAlign.toLowerCase() === 'left') return 'left';
    if (rawAlign && rawAlign.toLowerCase() === 'right') return 'right';
    if (rawAlign && rawAlign.toLowerCase() === 'center') return 'center';
    if (rawAlign && rawAlign.toLowerCase() === 'justify') return 'justify';
    return 'left';
  }

  /**
   * Used to toggle task lock status.
   *
   * @param index - number. toggles the lock status of nth row
   * @returns Promise (boolean)
   */
  toggleTaskLockStatus = async (index: any, old_status: boolean): Promise<boolean> => {
    const records = this.props.step.dataset.getRecords();
    const record: DataRecord | undefined = records[index];
    const status = !old_status;

    if (record && record.data && record.tableName) {
      const logicRef = new LogicEngine();
      LogicEngine.currentGroups = ['DataMonitor'];

      const l: LockManager = new LockManager(logicRef);

      if (status) {
        const result = await l.lockRecord(
          record.tableName,
          record.data.id,
          'Locked with toggle button',
          false,
        );

        if (
          result === LockManager.LockResult.Locked ||
          result === LockManager.LockResult.AlreadyLocked
        ) {
          toastr.success('Task Lock Success', 'Success');
          return status;
        } else {
          toastr.error('Task Lock Failure', 'Failure');
        }
      } else {
        const result = await l.unlockRecord(
          record.tableName,
          record.data.id,
          'Unlocked by Edit Fields',
          false,
        );

        if (
          result === LockManager.LockResult.Unlocked ||
          result === LockManager.LockResult.AlreadyUnlocked
        ) {
          toastr.success('Task Unlock Success', 'Success');
          return status;
        } else {
          toastr.error('Task Unlock Failure', 'Failure');
        }
      }
    }
    return record?.data.lock_status;
  };

  /**
   * Used to sort the table data.
   *
   * @param sortKey - string
   * @param sortDirection - string
   * @returns void
   */
  getSortedArray = (index: number, sortDirection: string) => {
    if (sortDirection === '') return this.props.step.getFormattedData();
    else if (sortDirection === 'desc') {
      return this.props.step?.getFormattedData().sort(function (_a: any, _b: any) {
        if (_a[index].sortValue < _b[index].sortValue) {
          return 1;
        } else if (_a[index].sortValue > _b[index].sortValue) {
          return -1;
        }
        return 0;
      });
    } else {
      if (sortDirection === 'asc') {
        return this.props.step?.getFormattedData().sort(function (_a: any, _b: any) {
          if (_a[index].sortValue > _b[index].sortValue) {
            return 1;
          } else if (_a[index].sortValue < _b[index].sortValue) {
            return -1;
          }
          return 0;
        });
      }
    }
  };

  /**
   * Used to update the table data by column sort.
   *
   * @param col - StepTable.Column.
   * @returns void
   */
  handleSortBy = (col: StepTable.Column, index: number) => {
    if (col.is_action) {
      return;
    }

    if (this.state.sortKey !== col.text) {
      this.setState({
        sortKey: col.text,
        sortKeyIndex: index,
        sortDirection: 'asc',
        tableData: this.getSortedArray(index, 'asc'),
      });
    } else {
      if (this.state.sortDirection === 'asc') {
        this.setState({
          sortKey: col.text,
          sortKeyIndex: index,
          sortDirection: 'desc',
          tableData: this.getSortedArray(index, 'desc'),
        });
      } else if (this.state.sortDirection === 'desc') {
        this.setState({
          sortKey: col.text,
          sortDirection: '',
          sortKeyIndex: -1,
          tableData: this.getSortedArray(index, ''),
        });
      } else {
        this.setState({
          sortKey: col.text,
          sortKeyIndex: index,
          sortDirection: 'asc',
          tableData: this.getSortedArray(index, 'asc'),
        });
      }
    }
  };

  /**
   * Used to render the sort icon for the column.
   *
   * @param col - StepTable.Column.
   * @returns DOM Element
   */
  renderSortIcon = (col: StepTable.Column) => {
    if (col.text !== this.state.sortKey || this.state.sortDirection === '') {
      return <img className="sort-icon" src={sortImg} />;
    } else if (this.state.sortDirection === 'asc') {
      return <img className="sort-icon" src={ascImg} />;
    } else {
      return <img className="sort-icon" src={descImg} />;
    }
  };

  public render(): JSX.Element {
    const step = this.props.step;

    const records = step.dataset.getRecords();

    return (
      <>
        <table className="table sortable-table" style={{ background: '#fff' }}>
          <thead>
            <tr className={`${this.state.sortKey}`}>
              {step.columns.map((col: StepTable.Column, index: number) => {
                const widthPercent = col.type.checkOption('width');
                return (
                  <th
                    className={'align-' + col.align}
                    style={{
                      fontWeight: 600,
                      cursor: 'pointer',
                      width: widthPercent ? widthPercent + '%' : '',
                    }}
                    onClick={() => this.handleSortBy(col, index)}
                    key={'head-col-' + col.text + index}
                  >
                    {col.text}
                    {!col.is_action && this.renderSortIcon(col)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.tableData.map((row: CellValue[], rowIndex: number) => {
              return (
                <tr key={'body-row-' + rowIndex}>
                  {row.map((cellData: CellValue, colIndex: number) => {
                    return (
                      <td
                        className={'align-' + step.columns[colIndex].align}
                        style={{ fontWeight: 600 }}
                        key={
                          'body-row-' + rowIndex + '-col-' + step.columns[colIndex].text + colIndex
                        }
                      >
                        {step.columns[colIndex].is_action ? (
                          cellData.text ? (
                            <Link to={cellData.link!} className="btn btn-primary btn-sm w-xs">
                              {cellData.text}
                              {cellData.icon && (
                                <i style={{ paddingLeft: '10px' }} className={cellData.icon} />
                              )}
                            </Link>
                          ) : (
                            ''
                          )
                        ) : step.columns[colIndex].type.checkOption('LockToggle') ? (
                          <LockSwitch
                            record={records[rowIndex]}
                            index={rowIndex}
                            toggleTaskLockStatus={this.toggleTaskLockStatus}
                          />
                        ) : (
                          cellData.text
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default PageTable;
