import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Spinner, Modal } from 'reactstrap';

/**
 * Imports specific to Laina Shared Platform
 */
import { PageManager } from '@lainaedge/platformshared';

import { RenderHelper } from './RenderHelper';

import { useData } from '../context/DataContext';

enum TPageState {
  Loading,
  Finished,
  Error,
  RouteMissing,
}

/**
 * QuickMenuRender component.
 *
 * @remarks
 * A component that parses menu config and renders Quick Menu content on the popup
 *
 * @component QuickMenuRender
 * @category Component
 */
const QuickMenuRender = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showQuickMenu, setShowQuickMenu, ...context } = useData();

  const [pageState, setPageState] = useState(TPageState.Loading);
  const [allComponents, setAllComponents] = useState<Array<JSX.Element>>([]);

  useEffect(() => {
    pageLoad();
  }, []);

  const pageLoad = async () => {
    /** Load page by route from PageManager */
    const routeName = '/page/menu_quickaccess';

    PageManager.allowEdcConfig = true;
    PageManager.allowMobileConfig = true;
    const page = await PageManager.instance().findRoute(routeName);

    page.evInitializePage.subscribe(async (metaData: any) => {
      setPageState(TPageState.Loading);
    });

    page.evFinishedPage.subscribe((metaData: any) => {
      /** On finishing page load, update the pageState state to hide the loading spinner */
      setPageState(TPageState.Finished);
    });

    if (page && page.isValid()) {
      const rend_helper = new RenderHelper();

      rend_helper.navigate = navigate;
      rend_helper.location = location;
      rend_helper.context = context;
      await page.processPage(rend_helper);

      const list: JSX.Element[] = [];
      rend_helper.currentTarget.forEach((e) => {
        list.push(e as JSX.Element);
      });

      setAllComponents(list);
    } else {
      // Error condition, unable to find page
      setPageState(TPageState.RouteMissing);
    }
  };

  return (
    <Modal size="lg" isOpen={showQuickMenu}>
      <div className="modal-header">
        <h2 className="modal-title mt-0">Quick Access Menu</h2>
        <button
          type="button"
          onClick={(): void => setShowQuickMenu(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {pageState === TPageState.Loading ? (
          <Container fluid className="loader-container">
            <Spinner className="mr-2" color="primary" />
          </Container>
        ) : (
          <Container fluid>{allComponents}</Container>
        )}
      </div>
    </Modal>
  );
};

export default QuickMenuRender;
