import React, { Component } from 'react';

/**
 * Visual primitives for the component age. Use the best bits of ES6 and CSS to style your apps without stress
 *
 * @remarks
 *
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import styled from 'styled-components';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepList } from '@lainaedge/platformshared';
// import LockOpen from  '../../assets/images/lock-open.png';
// import LockClosed from  '../../assets/images/lock-closed.png';
import { RenderHelper } from '../RenderHelper';

const ListItem = styled.tr`
  cursor: pointer;
`;

const NoteListItem = styled.li`
  cursor: pointer;
`;

/**
 * Props for [[`PageListItem`]] component
 */
export interface PageListItemProps {
  /** Step data */
  step: StepList;
  /** Task data */
  item: any;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageListItem component
 *
 * @component PageListItem
 * @category PageElements
 */
export default class PageListItem extends Component<PageListItemProps> {
  constructor(props: PageListItemProps) {
    super(props);

    this.state = {};
  }

  /**
   * NoteListItem click hanlder, used to redirect.
   */
  handleClick = async () => {
    const item = this.props.item;
    //window.location.href = item.click!;

    this.props.renderer.navigate(item.click);
  };

  /**
   * Used to prevent the onChange event.
   *
   * @param e - Event object
   * @returns Promise
   */
  handleFormLock = async (e: any) => {
    e.stopPropagation();
  };

  /**
   * Renders PageListItem class component.
   */
  public render(): JSX.Element {
    const item = this.props.item;

    const badgeClass =
      item.TaskStatus === 'Pending' ? 'badge badge-warning' : 'badge badge-primary';

    const style = this.props.step.listOptions.style;

    if (item.style === 'Note List:') {
      return (
        <NoteListItem className="event-list" onClick={this.handleClick}>
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="media">
            <div className="mr-3">
              <h5 className="font-size-14">
                {item.TaskStatus}{' '}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
              </h5>
            </div>
            <div className="media-body">
              <div>{item.TaskTitle}</div>
            </div>
          </div>
        </NoteListItem>
      );
    }

    return (
      <ListItem onClick={this.handleClick}>
        <td className="text-right" style={{ width: 24 }}>
          <span>
            <i className={item.TaskIcon + ' font-size-24'} />
          </span>
        </td>
        <td>
          <h5 className="text-wrap">
            {style == 'Medications List' ? JSON.parse(item.TaskTitle)?.name : item.TaskTitle}
          </h5>
          <p className="text-muted mb-0">{item.TaskSubTitle}</p>
        </td>
        <td className="text-right">
          <span className={badgeClass + ' font-size-14'}>{item.TaskStatus}</span>
        </td>
        <td>
          <span> &gt; </span>
        </td>
        {!item.hidelocks && (
          <td>
            <i
              className={
                item.lockstatus ? 'fas fa-lock font-size-18' : 'fas fa-lock-open font-size-18'
              }
            />
          </td>
        )}
        {/* <td><img src={item.lockstatus ? LockOpen : LockClosed} alt="" className="lock-icon" /></td> */}
      </ListItem>
    );
  }
}
