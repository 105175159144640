import React, { useEffect, useRef, useState } from 'react';
import { Alert, Spinner, Label } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { DataServices } from '@lainaedge/platformshared';
import { ValidateParticipantResult } from '@lainaedge/platformshared/src/types/ValidateParticipantResult';

import { IPariticipantStudyFormFields } from '../../store/types';

import Layout from '../../components/StudyNonAuthLayout';

const dataService = DataServices.instance();

const myProjectName = process.env.REACT_APP_PROJECT_NAME
  ? process.env.REACT_APP_PROJECT_NAME
  : 'RB1';

/**
 * Study component.
 *
 * @remarks
 * Page where participant can log in
 *
 * @component Study
 * @category Page
 */
const Study = (): JSX.Element => {
  const navigate = useNavigate();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  // handleValidSubmit
  async function handleValidSubmit(values: IPariticipantStudyFormFields) {
    SetIsSubmitting(true);
    const result: ValidateParticipantResult = await dataService.validateParticipantHubServer(
      values.participantId,
    );
    if (result.success) {
      if (result.passwordStatus === 'needed') {
        if (result.trialName?.toLowerCase() !== myProjectName.toLowerCase()) {
          window.location.href =
            'https://' + result.trialAddress + '/study/setPassword/' + values.participantId;
        } else {
          navigate('/study/setPassword/' + values.participantId);
        }
      } else {
        if (result.trialName?.toLowerCase() !== myProjectName.toLowerCase()) {
          window.location.href =
            'https://' + result.trialAddress + '/study/enterPassword/' + values.participantId;
        } else {
          navigate('/study/enterPassword/' + values.participantId);
        }
      }
    } else {
      setErrorMessage(result.errorMessage!);
    }

    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          participantId: '',
        }}
        validationSchema={Yup.object().shape({
          participantId: Yup.string().required('Enter Participant ID'),
        })}
        onSubmit={(values) => {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
            <div className="mb-3">
              <Label for="participantId" className="form-label">
                Please enter the survey ID or participant ID that has been assigned to you
              </Label>
              <Field
                name="participantId"
                id="participantId"
                type="input"
                required
                className={
                  'form-control' +
                  (errors.participantId && touched.participantId ? ' is-invalid' : '')
                }
              />
              <ErrorMessage name="participantId" component="div" className="invalid-feedback" />
            </div>

            <div className="mt-3 d-grid">
              <button
                className="btn btn-login btn-block waves-effect waves-light save-btn"
                type="submit"
                disabled={isSubmitting}
              >
                Sign In
                {isSubmitting && <Spinner className="spinner" />}
              </button>
            </div>

            <div className="mt-3">
              <a
                className="btn btn-light-login btn-block waves-effect waves-light save-btn"
                href="/login"
              >
                I have a coordinator username
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default Study;
