import React from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';
import Autocomplete from 'react-autocomplete';

import FormInput from './FormInput';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';
import axios from 'axios';
import debounce from 'debounce';
import { InputProps } from './types';

/**
 * MedicalHistoryName component @extends FormInput
 *
 * @component MedicalHistoryName
 * @category FormElements
 */
export default class MedicalHistoryName extends FormInput {
  constructor(props: InputProps) {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
    };
  }

  componentDidMount() {
    const field = this.props.formProps.field;
    const medicalHistoryStr = this.props.formProps.step.getValueDatabase(field.field);
    console.log('MedicalHistoryName: initial medicationId', medicalHistoryStr);
    if (medicalHistoryStr) {
      let medicalHistory: any = {};
      try {
        medicalHistory = JSON.parse(medicalHistoryStr);
      } catch (error) {}
      this.setState({ options: [medicalHistory] });
    }
  }

  getMedicationInfo = async (medicalHistoryId: string) => {
    if (medicalHistoryId) {
      const dosesData = await axios.get(
        `https://uat.demo.trial.lainaent.com/api/v1/drugs/meddra?id=${medicalHistoryId}`,
      );
      if (dosesData && dosesData.data) {
        console.log(dosesData.data);
        if (dosesData.data.length > 0) {
          this.setState({ options: [dosesData.data[0]] });
        }
      }
    }
  };

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  handleChangeMedicationSuggest = (field: StepForm.FieldInfo, e: any) => {
    if (this.isEditFieldOnModal()) {
      this.props.formProps.handleChangeEditValues(field, e.target.value);
    } else {
      this.setState({ myFieldValue: e.target.value });
    }
    this.searchDelayed(e.target.value);
  };

  getSuggestionsData = async (queryStr: string) => {
    const optionsData = await axios.get(
      `https://uat.demo.trial.lainaent.com/api/v1/drugs/meddra?query=${queryStr}`,
    );
    if (optionsData && optionsData.data) {
      console.log('MedicalHistoryName: query', queryStr, optionsData.data);
      this.setState({ options: optionsData.data });
    }
  };

  searchDelayed = debounce(this.getSuggestionsData, 500);

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelectMedicationSuggest = (field: StepForm.FieldInfo, val: any) => {
    const options = this.state.options || [];

    console.log('MedicalHistoryName: selected value', val);
    /** Represents the first item in the list which english text is equal to value. */
    const selected = options.find((item) => item.name === val);

    if (this.isEditFieldOnModal()) {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
      this.setState({ myFieldValue: val });
    }
  };

  /**
   * Renders MedicationSuggest class component.
   */
  public render(): JSX.Element {
    const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const options = this.state.options || [];
    let fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    const dbValue = this.props.formProps.step.getValueDatabase(field.field);
    let parsedValue: any = {};
    try {
      parsedValue = dbValue ? JSON.parse(dbValue) : {};
    } catch (error) {}
    // if (parsedValue?.name && fieldValue == '') {
    //   fieldValue = parsedValue?.name;
    // }
    const selected = options.find((item) => item._id === parsedValue._id);
    if (fieldValue == dbValue && selected) {
      fieldValue = selected?.name;
    }

    try {
      const fieldObj = JSON.parse(fieldValue);
      if (fieldObj?.name) {
        fieldValue = fieldObj.name;
      }
    } catch (error) {}

    console.log(options, fieldValue);

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <Autocomplete
            getItemValue={(item: any) => item?.name}
            items={options}
            renderItem={(item: any, isHighlighted: boolean) => (
              <div
                key={item._id}
                style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '5px' }}
                dangerouslySetInnerHTML={{
                  __html: item?.name?.replace(fieldValue, `<strong>${fieldValue}</strong>`),
                }}
              ></div>
            )}
            value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
            onChange={(e: any) => {
              this.handleChangeMedicationSuggest(field, e);
            }}
            onSelect={(val: any) => {
              this.handleSelectMedicationSuggest(field, val);
            }}
            menuStyle={{
              zIndex: 2,
              display: 'block',
              width: '100%',
              backgroundColor: '#fff',
              position: 'absolute',
              top: '45px',
              left: 0,
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              maxHeight: 'min(300px, 50vh)',
              overflow: 'auto',
            }}
            inputProps={{
              className: 'form-control autocomplete',
              disabled:
                this.isEditMode() ||
                is_disabled ||
                this.props.formProps.hide_fields[field.field] ||
                this.props.formProps.field.enabled === false,
              name: field.field,
            }}
            wrapperProps={{
              className: 'w-100',
            }}
            wrapperStyle={{
              position: 'relative',
            }}
          />
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
