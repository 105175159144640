import React from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';

import FormInput from './FormInput';

/**
 * A lightweight library that converts raw HTML to a React DOM structure.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://www.npmjs.com/package/html-to-react}
 * or {@link https://github.com/aknuds1/html-to-react}
 * @packageDocumentation
 */
const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * Boolean component
 *
 * @component Boolean
 * @category FormElements
 */
export default class Boolean extends FormInput {
  /**
   * Used to change the value of a radio field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   */
  handleChangeRadio = (field: StepForm.FieldInfo, e: any) => {
    this.setValue(field, e.target.value);
  };

  /**
   * Renders Boolean class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    /** Represents a text formatted version of the field value */
    const myValueText = step.getValueFormatted(field.field);

    const checkbox_values = ['Yes', 'No'];
    return (
      <>
        {checkbox_values
          ? checkbox_values.map((item: any) => (
              <div
                className="custom-control custom-radio boolean-checkbox mb-3 mr-3"
                key={this.isEditFieldOnModal() ? 'edit_value' + item : field.field + item}
              >
                <input
                  type="radio"
                  id={this.isEditFieldOnModal() ? 'e' + field.field + item : field.field + item}
                  // className={
                  //   this.state.errors[field.field] ? 'is-invalid custom-control-input form-control' : 'custom-control-input form-control'
                  // }
                  className="custom-control-input"
                  name={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field
                      : this.isEditMode()
                      ? 'm' + field.field
                      : field.field
                  }
                  value={item}
                  checked={
                    this.isEditFieldOnModal()
                      ? this.props.formProps.edit_values[field.field] === item
                      : this.props.formProps.hide_fields[field.field]
                      ? false
                      : myValueText === item
                  }
                  disabled={this.isFieldDisabled()}
                  onChange={(e: any) => {
                    if (this.isEditFieldOnModal()) {
                      this.props.formProps.handleChangeEditValues(field, e.target.value);
                    } else {
                      this.handleChangeRadio(field, e);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={
                    this.isEditFieldOnModal() ? 'e' + field.field + item : field.field + item
                  }
                >
                  {htmlToReactParser.parse(item)}
                </label>
              </div>
            ))
          : ''}
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
