import React, { Component } from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { Button, Table, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Imports specific from Laina Shared Platform
 */
import { DataRecord, QueryLog } from '@lainaedge/platformshared';

/**
 * Library to parse, validate, manipulate and display dates and times in JavaScript.
 *
 * @remarks
 *
 * Take a look at official documentation: {@link https://momentjs.com/}
 * @packageDocumentation
 */
import moment from 'moment';
import classNames from 'classnames';

/**
 * Props for [[`FieldQueryItem`]] component
 */
export interface FieldQueryItemProps {
  /** Database records to be displayed. */
  records: DataRecord[];
  /** Lock history items to be displayed. */
  lockHistory: DataRecord[];
  /** If set to true renders Reply button. */
  is_edit_mode: boolean;
  is_query_mode: boolean;
  field_name: string;
  showReplyModal: (record: DataRecord) => void;
  acceptQuery: (record: DataRecord) => void;
  closeQuery: (record: DataRecord) => void;
}

/**
 * FieldQueryItem component
 *
 * @component FieldQueryItem
 * @category FormElements
 */
export default class FieldQueryItem extends Component<FieldQueryItemProps> {
  state = {
    isOpen: false,
  };

  constructor(props: FieldQueryItemProps) {
    super(props);
  }

  /**
   * Renders Queries for the field/record.
   */
  renderQueriesTable() {
    const is_edit_mode = this.props.is_edit_mode;
    const is_query_mode = this.props.is_query_mode;

    const lockDates = this.props.lockHistory
      .filter((r: DataRecord) => r.data.event_text.includes(' locked'))
      .map((r: DataRecord) => r.data.event_dt_local);

    return (
      <div className="table-responsive field-query-table">
        <Table className="table table-sm m-0" style={{ borderSpacing: 0 }}>
          <thead>
            <tr>
              <th className="date">Date</th>
              <th className="user">User</th>
              <th>Query</th>
              <th>Response</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.records.map((record: DataRecord) => {
              return (
                <tr
                  key={record.id}
                  className={classNames(
                    record.data.query_status === QueryLog.QueryStatus.Open ? 'red-border' : '',
                    moment(record.data.event_dt_local).isAfter(lockDates[lockDates.length - 1])
                      ? 'light-red-bg'
                      : '',
                  )}
                >
                  <th className="date" scope="row">
                    {moment(record.data.event_dt_local).format('YYYY-MM-DD')}
                  </th>
                  <td className="user">{record.data.reported_by}</td>
                  <td>{record.data.message}</td>
                  <td>{record.data.response}</td>
                  <td>
                    {record.data.query_status === QueryLog.QueryStatus.Open && 'Open'}
                    {record.data.query_status === QueryLog.QueryStatus.Answered && 'Answered'}
                    {record.data.query_status === QueryLog.QueryStatus.Resolved && 'Resolved'}
                    {record.data.query_status === QueryLog.QueryStatus.Deleted && 'Closed'}
                    {record.data.query_status === QueryLog.QueryStatus.NotResolved &&
                      'Not Resolved'}
                  </td>
                  <td>
                    {is_query_mode && record.data.query_status === QueryLog.QueryStatus.Open && (
                      <Button
                        className="save-btn btn-sm mr-1"
                        type="button"
                        color="primary"
                        onClick={() => this.props.closeQuery(record)}
                      >
                        Close
                      </Button>
                    )}
                    {is_edit_mode && record.data.query_status === QueryLog.QueryStatus.Open && (
                      <Button
                        className="save-btn btn-sm"
                        type="button"
                        color="primary"
                        onClick={() => this.props.showReplyModal(record)}
                      >
                        Reply
                      </Button>
                    )}
                    {is_query_mode && record.data.query_status === QueryLog.QueryStatus.Answered && (
                      <Button
                        className="save-btn btn-sm mr-1"
                        type="button"
                        color="primary"
                        onClick={() => this.props.acceptQuery(record)}
                      >
                        Accept
                      </Button>
                    )}
                    {is_query_mode && record.data.query_status === QueryLog.QueryStatus.Answered && (
                      <Button
                        className="save-btn btn-sm"
                        type="button"
                        color="primary"
                        onClick={() => this.props.showReplyModal(record)}
                      >
                        Review
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  /**
   * Renders FieldQueryItem class component.
   */
  public render(): JSX.Element {
    const openQueries = this.props.records.filter((record: DataRecord) => {
      return record.data.query_status === QueryLog.QueryStatus.Open;
    });

    const wrapperClassname = openQueries.length
      ? 'record-foldable open-queries'
      : 'record-foldable closed-queries';

    if (this.props.field_name == 'FORM') {
      return this.renderQueriesTable();
    }

    if (openQueries.length) {
      return (
        <div className={'col-md-12'}>
          <div className={wrapperClassname}>
            <div className="foldable-title row justify-content-between align-items-center mr-1">
              <div>
                <b>There is an open query for this field</b>
              </div>
            </div>
            <div className="foldable-content">{this.renderQueriesTable()}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={'col-md-12'}>
        <div className={wrapperClassname}>
          <div
            className="foldable-title row justify-content-between align-items-center mr-1"
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <div>
              <b>There has been {this.props.records.length} queries on this field</b>
            </div>
            <div>
              <span className="action-title">
                {this.state.isOpen ? 'Close Query History' : 'Show Query History'}
              </span>
              <span className="action-icon mr-1">
                <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'} />
              </span>
            </div>
          </div>
          <div className="foldable-content">
            <Collapse isOpen={this.state.isOpen}>{this.renderQueriesTable()}</Collapse>
          </div>
        </div>
      </div>
    );
  }
}
