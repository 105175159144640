import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

//i18n
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { useAuth } from '../../../context/AuthContext';
import { useData } from '../../../context/DataContext';
import { ReactComponent as ChevronDownIcon } from '../../../assets/images/icons/chevron-down.svg';

const ProfileMenu = (): JSX.Element => {
  const { logout } = useAuth();
  const { setShowQuickMenu } = useData();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [cookies] = useCookies();

  const [username, setusername] = useState('Admin');

  const { t } = useTranslation();

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const authUser = cookies['authUser'];
    if (authUser) {
      setusername(authUser.username);
    }
  }, [cookies]);

  const handleClickQuickAccess = () => {
    setMenu(!menu);
    setShowQuickMenu(true);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown d-flex align-items-center"
          tag="button"
        >
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}
          <i className="bx bx-user-circle font-size-24 d-xl-none" />
          <span className="d-none d-xl-inline-block ml-3 mr-2 font-size-15 font-weight-bold">
            {username}
          </span>
          <ChevronDownIcon />
        </DropdownToggle>
        <DropdownMenu right>
          <div className="dropdown-item" onClick={handleClickQuickAccess}>
            <i className="fa-thin fa-square-list font-size-16 align-middle mr-1"></i>
            <span>{t('Quick Menu')}</span>
          </div>
          <div className="dropdown-divider"></div>
          <Link to="/changepassword" className="dropdown-item" onClick={() => setMenu(!menu)}>
            <i className="bx bx-user font-size-16 align-middle mr-1" />
            <span>{t('Change Password')}</span>
          </Link>
          <div className="dropdown-item logout-div" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span>{t('Logout')}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
