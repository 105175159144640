import React, { useEffect } from 'react';

import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

// layouts Format
import AuthLayout from './components/AuthLayout';
import NonAuthLayout from './components/NonAuthLayout';
import ParticipantLayout from './components/ParticipantLayout';
import { PingResult } from '@lainaedge/platformshared/src/types/PingResult';

import { MenuItem } from './constants/types';

// Import scss
import './assets/scss/theme.scss';

// Fontawesome
import '@fortawesome/fontawesome-pro/js/all';

// context
import { useData } from './context/DataContext';

import Login from './pages/Authentication/Login';
import ForgetPwd from './pages/Authentication/ForgetPassword';

import ParticipantStudy from './pages/Participant/Study';
import ParticipantEnterPassword from './pages/Participant/EnterPassword';
import ParticipantSetPassword from './pages/Participant/SetPassword';
import ParticipantForgotPassword from './pages/Participant/ForgotPassword';
import MagicPage from './pages/MagicPage';
import CreateMagicLink from './pages/CreateMagicLink';
import TrialWatch from './pages/TrialWatch';

import DefaultPage from './pages/DefaultPage';
import HubMagicLoginPage from './pages/HubMagicLoginPage';

import ChangePwd from './pages/ChangePassword';
import MainPageRender from './components/MainPageRender';
import IdleWatch from './components/IdleWatch';
import { DataServices } from '@lainaedge/platformshared';

// services
import { getAppVersion, getEDCPortalConfig } from './services';

import { useAuth } from './context/AuthContext';

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

/** Set up project environment for DataServices */
DataServices.setEnvironment(
  (process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat') as Environment,
);
DataServices.setProjectName(
  process.env.REACT_APP_PROJECT_NAME ? process.env.REACT_APP_PROJECT_NAME : 'RB1',
);
DataServices.setServerAddress('');

const guestRoutes = [
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/study/setPassword/:pid', component: ParticipantSetPassword },
  { path: '/study/enterPassword/:pid', component: ParticipantEnterPassword },
  { path: '/study/forgotPassword/:pid', component: ParticipantForgotPassword },
  { path: '/study', component: ParticipantStudy },
  // { path: '/system/magicPage', component: MagicPage },
  { path: '/newMagic', component: CreateMagicLink },
  { path: '/loading', component: DefaultPage },
  { path: '/magic/:code', component: HubMagicLoginPage },
];

const internalRoutes = [
  { path: '/changepassword', component: ChangePwd },
  { path: '/page/:page', component: MainPageRender },
  { path: '/:task/page/:page', component: MainPageRender },
  { path: '/_edit/:task/page/:page', component: MainPageRender },
  { path: '/_edit/page/:page', component: MainPageRender },
  { path: '/_edit/page/:page/:pid/:id', component: MainPageRender },
  { path: '/_editqueryid', component: MainPageRender },
  { path: '*', component: MainPageRender },
  { path: '/trialwatch', component: TrialWatch },
];

const App = () => {
  const { isAuthenticated, setDefaultRoute } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setVersionInfo,
    setConfig,
    setDashTitle,
    setTitleText,
    setFooterRightText,
    setMenuItems,
    setFooterText,
    setLogo,
    setLogoSm,
  } = useData();

  useEffect(() => {
    async function getData() {
      const version: PingResult = await getAppVersion();
      if (version) {
        setVersionInfo(version);
      }

      const config = await getEDCPortalConfig();

      if (config) {
        let level = 0;
        const menuItems: Array<MenuItem> = [];
        let index = 0;
        let in_submenu = 0;

        // build menuItems
        const Sidebar = config.Sidebar;
        Sidebar.map((item: any) => {
          if (!item.item_type) item.item_type = 'Link';
          if (level === 0 && item.item_type === 'Link') {
            in_submenu = 0;
            menuItems.push({
              ...item,
              children: [],
            });
            index++;
          } else if (item.item_type.startsWith('Menu')) {
            if (in_submenu === 1) index++;
            in_submenu = 1;

            level = 1;
            menuItems.push({
              ...item,
              children: [],
            });
          } else if (item.item_type === 'Link' && level === 1) {
            if (!item.route) item.route = '/page/screenfailed';
            menuItems[index].children.push({
              ...item,
              children: [],
            });
          }
          return item;
        });

        let logo = 'laina-logo.png';
        let logoSm = 'laina-logo-sm.png';
        let title_text = 'LAINA EDC PORTAL';
        let footer_text = new Date().getFullYear() + ' © Laina';
        let footer_text_right = '';
        let dashTitle = '';

        const WebsiteConfig = config.tables.WebsiteConfig;
        const settings = WebsiteConfig[0].data;
        // build Site Config
        if (settings) {
          const mainLogo: any = settings.find((item: any) => item.setting === 'Logo-main');
          if (mainLogo) logo = mainLogo.value;

          const smallLogo: any = settings.find((item: any) => item.setting === 'Logo-small');
          if (smallLogo) logoSm = smallLogo.value;

          const faviconObj: any = settings.find((item: any) => item.setting === 'Favicon');
          if (faviconObj) {
            const favicon: HTMLElement = document.getElementById('favicon')!;
            favicon.setAttribute('href', '/' + faviconObj.value);
          }

          const title: any = settings.find((item: any) => item.setting === 'Title');
          if (title) title_text = title.value;

          const footer: any = settings.find((item: any) => item.setting === 'Footer_text');
          if (footer) footer_text = footer.value;

          const footer_right: any = settings.find(
            (item: any) => item.setting === 'Footer_text_right',
          );
          if (footer_right) footer_text_right = footer_right.value;

          const dash_title: any = settings.find((item: any) => item.setting === 'DashTitle');
          if (dash_title) dashTitle = dash_title.value;

          const default_route: any = settings.find((item: any) => item.setting === 'default_route');
          setDefaultRoute(default_route ? default_route.value : '/login');
        }

        setConfig(config);
        setMenuItems(menuItems);
        setLogo(logo);
        setLogoSm(logoSm);
        setTitleText(title_text);
        setFooterText(footer_text);
        setFooterRightText(footer_text_right);
        setDashTitle(dashTitle);
      }

      // const dataDictionary = await getDataDictionary();
    }

    getData();
    // eslint-disable-next-line
  }, []);

  const Dashboard = (): JSX.Element => <Navigate to="/page/portaldashboard" />;

  internalRoutes.push({
    path: '/',
    component: Dashboard,
  });

  return (
    <React.Fragment>
      <IdleWatch />

      <Routes>
        <Route path="/" element={<AuthLayout />}>
          {internalRoutes.map(({ component, path }, idx) => {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  isAuthenticated ? (
                    React.createElement(component, { navigate, location })
                  ) : (
                    <Navigate to={{ pathname: '/loading' }} />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/" element={<NonAuthLayout />}>
          {guestRoutes.map(({ component, path }, idx) => {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  !isAuthenticated ? (
                    React.createElement(component, {})
                  ) : (
                    <Navigate to={{ pathname: '/' }} />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/" element={<ParticipantLayout />}>
          <Route
            path={'/system/magicPage'}
            element={
              !isAuthenticated ? (
                React.createElement(MagicPage, {})
              ) : (
                <Navigate to={{ pathname: '/' }} />
              )
            }
          />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

App.displayName = 'App';

export default App;
