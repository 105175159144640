import React, { Component } from 'react';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import { Row, Col } from 'reactstrap';

//Simple bar
import SimpleBar from 'simplebar-react';

import { RenderHelper } from '../RenderHelper';

import PageListItem from './PageListItem';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepList } from '@lainaedge/platformshared';

/**
 * Props for [[`PageList`]] component
 */
export interface PageListProps {
  /** Array of components */
  components: JSX.Element[];
  /** Array of list items */
  items?: any[];
  /** Step data */
  step: StepList;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * Used for state for [[`PageList`]] component
 */
export interface PageListState {
  /** String representation of task sorting direction */
  sortDirection: string;
  /** Array of list items */
  items: any[];
}

/**
 * PageList component
 *
 * @component PageList
 * @category PageElements
 */
export default class PageList extends Component<PageListProps> {
  state: PageListState = {
    sortDirection: '',
    items: this.props.items ? this.props.items : [],
  };

  constructor(props: PageListProps) {
    super(props);

    this.handleChangeTaskListSort = this.handleChangeTaskListSort.bind(this);
  }

  /** Handle Switch  Task List Sorting */
  handleChangeTaskListSort(): void {
    const newDirection =
      this.state.sortDirection === '' ? 'asc' : this.state.sortDirection === 'asc' ? 'desc' : '';

    this.setState({
      sortDirection: newDirection,
      items: this.sortItems(newDirection),
    });
  }

  /**
   * Used to sort the task list.
   *
   * @param sortDirection - string
   * @returns Promise (void)
   */
  sortItems = (sortDirection: string) => {
    if (!this.props.items) return [];
    if (sortDirection === '') return this.props.items;
    else if (sortDirection === 'desc') {
      return this.props.items?.sort(function (_a: any, _b: any) {
        if (_a.TaskTitle < _b.TaskTitle) {
          return 1;
        } else if (_a.TaskTitle > _b.TaskTitle) {
          return -1;
        }
        return 0;
      });
    } else {
      if (sortDirection === 'asc') {
        return this.props.items?.sort(function (_a: any, _b: any) {
          if (_a.TaskTitle > _b.TaskTitle) {
            return 1;
          } else if (_a.TaskTitle < _b.TaskTitle) {
            return -1;
          }
          return 0;
        });
      }
    }
  };

  /**
   * Renders PageList class component.
   */
  public render(): JSX.Element {
    const components = this.props.components;
    const step = this.props.step;
    const style = step.listOptions.style;

    return (
      <Row>
        <Col md={12} className="mt-2">
          <h4>{style}</h4>
          {style === 'Note List:' ? (
            <SimpleBar style={{ maxHeight: '310px' }}>
              <ul className="verti-timeline list-unstyled">{components}</ul>
            </SimpleBar>
          ) : (
            <table className="task-list-table project-list-table table-nowrap table-centered table-borderless table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h5 className="text-truncate">
                      Title
                      <i
                        className={
                          this.state.sortDirection
                            ? 'fa fa-lg fa-sort-' + this.state.sortDirection
                            : 'fa fa-lg fa-sort'
                        }
                        onClick={() => this.handleChangeTaskListSort()}
                      ></i>
                    </h5>
                  </th>
                  <th className="text-right">
                    <h5 className="text-truncate">Status</h5>
                  </th>
                  <th></th>
                </tr>
              </thead>
              {step.listOptions.listEmpty && <tbody> {step.listOptions.listEmpty} </tbody>}
              <tbody>
                {this.state.items?.map((item: any, index: number) => {
                  return (
                    <PageListItem
                      key={this.props.step.key_id + index}
                      item={item}
                      renderer={this.props.renderer}
                      step={this.props.step}
                    ></PageListItem>
                  );
                })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  }
}
