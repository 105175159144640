import { DataServices } from '@lainaedge/platformshared';

import {
  ILoginFormFields,
  IParticipantLoginFormFields,
  IForgetPWFormFields,
  IResetPWFormFields,
  IParticipantForgetPWFormFields,
  IParticipantResetPWFormFields,
} from '../store/types';

import axios from 'axios';

const dataService = DataServices.instance();

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

const myEnv = process.env.REACT_APP_SERVER_ENV
  ? process.env.REACT_APP_SERVER_ENV.toLowerCase()
  : 'uat';
const myProjectName = process.env.REACT_APP_PROJECT_NAME
  ? process.env.REACT_APP_PROJECT_NAME.toLowerCase()
  : 'rb1';

DataServices.setEnvironment(
  (process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat') as Environment,
);
DataServices.setProjectName(
  process.env.REACT_APP_PROJECT_NAME ? process.env.REACT_APP_PROJECT_NAME : 'RB1',
);
DataServices.setServerAddress('');

const apiBaseUrl = `https://${myEnv}.${myProjectName}.trial.lainaent.com/api/v1`;

export function getAppVersion() {
  return dataService.pingServer();
}

export function getEDCPortalConfig() {
  return dataService.getEDCPortalConfig();
}

export function getDataDictionary() {
  return dataService.getDataDictionary();
}

export function login(values: ILoginFormFields) {
  return dataService.authUser(values.username.toLowerCase(), values.password, {
    version: '0.0.39',
  });
}

export function participantLogin(values: IParticipantLoginFormFields) {
  return dataService.authParticipant(values.participantId.toLowerCase(), values.password, {
    version: '0.0.39',
  });
}

export function participantResetPasswordRequest(values: IParticipantForgetPWFormFields) {
  return dataService.participantResetPasswordRequest(values.email);
}

export function participantResetPasswordWithCode(values: IParticipantResetPWFormFields) {
  return dataService.participantResetPasswordWithCode(
    values.participantId,
    values.confirmationCode,
    values.newPassword,
  );
}

export function resetPasswordRequest(values: IForgetPWFormFields) {
  return dataService.resetPasswordRequest(values.username);
}

export async function resetPasswordWithCode(values: IResetPWFormFields) {
  return dataService.resetPasswordWithCode(
    values.username,
    values.verificationCode,
    values.password,
  );
}

export function authMagicCode(magicCode: string, study: string) {
  return axios.post(`${apiBaseUrl}/auth/auth-magic-code`, {
    magicCode,
    study,
    metadata: {},
  });
}
