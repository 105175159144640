import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';
import { useData } from '../../context/DataContext';

const Breadcrumb = () => {
  const { breadCrumbs } = useData();

  return (
    <Row className="breadcrumbs">
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadCrumbs.map((item, index) => {
                return (
                  <BreadcrumbItem key={'breadcrumb' + index}>
                    {breadCrumbs.length - 1 !== index ? (
                      <Link to={item.link}>{item.text}</Link>
                    ) : (
                      item.text
                    )}
                  </BreadcrumbItem>
                );
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
