import React, { Component } from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepDashButton } from '@lainaedge/platformshared';

/**
 * Visual primitives for the component age. Use the best bits of ES6 and CSS to style your apps without stress
 *
 * @remarks
 *
 * Take a look at official documentation:
 * {@link https://reactstrap.github.io/} or
 * {@link https://github.com/reactstrap/reactstrap}
 * @packageDocumentation
 */
import styled from 'styled-components';

/**
 * Stateless React Components for Bootstrap 4.
 *
 * @remarks
 *
 * Library contains React Bootstrap 4 components that favor composition and control.
 * Take a look at official documentation:
 * {@link https://styled-components.com/} or
 * {@link https://github.com/styled-components/styled-components}
 * @packageDocumentation
 */
import { Col } from 'reactstrap';

import { RenderHelper } from '../RenderHelper';

const DashButtonIcon = styled.div`
  background: white;
  border-radius: 1rem;
  border: 2px solid #f1b44c;
  padding: 2rem;

  i {
    font-size: 2rem;
  }
`;

const DashButton = styled.button`
  margin-top: -1rem;
  width: 80%;
  background: #f1b44c;
  border-radius: 1rem;
  border: none;
  padding: 1rem 0.5rem;
  color: white;
`;

/**
 * Props for [[`PageDashButton`]] component
 */
export interface PageDashButtonProps {
  /** Used run processTextReplacement. */
  logic: any;
  /** Step data.*/
  step: StepDashButton;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageDashButton component
 *
 * @component PageDashButton
 * @category PageElements
 */
export default class PageDashButton extends Component<PageDashButtonProps> {
  constructor(props: PageDashButtonProps) {
    super(props);

    this.state = {};
  }

  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to step.raw.option_1
   */
  handleClick = async () => {
    const step = this.props.step;
    //const logic = await LogicEngine.instance();
    //window.location.href = logic.processTextReplacement(step.raw.option_1);
    //window.location.href = step.raw.option_1!;
    this.props.renderer.navigate(step.raw.option_1);
  };

  /**
   * Renders PageDashButton class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;
    const processedText = this.props.logic.processTextReplacement(step.text);
    //const iconConfig = step.icon.replace("fa-", "");
    return (
      <Col className="text-center justify-content-center">
        <DashButtonIcon className="pt-3 container-fluid d-flex justify-content-center">
          <i className={step.icon}></i>
        </DashButtonIcon>
        <DashButton type="button" color="warning" onClick={this.handleClick}>
          <p className="font-weight-medium mt-1 mb-1">{processedText}</p>
        </DashButton>
      </Col>
    );
  }
}
