import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Form, InputGroup, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StepForm } from '@lainaedge/platformshared';
import classnames from 'classnames';

/**
 * Props for [[`FormEditModal`]] component
 */
interface ModalProps {
  isShowEditModal: boolean | undefined;
  handleEditSave: (reason: string) => Promise<void>;
  toggleEditModal: () => void;
  edit_items?: Array<StepForm.FieldInfo>;
  error: string;
  activeTab: number;
  renderFormField: (field: StepForm.FieldInfo, is_on_modal: boolean, is_disabled?: boolean) => void;
  toggleTab: (tab: number) => void;
}

/**
 * FormEditModal component
 *
 * @component FormEditModal
 * @category Component
 */
const FormEditModal = ({
  isShowEditModal,
  handleEditSave,
  toggleEditModal,
  edit_items,
  error,
  activeTab,
  renderFormField,
  toggleTab,
}: ModalProps): JSX.Element => {
  const [reason, setReason] = useState<string>('');

  return (
    <Modal size="lg" isOpen={isShowEditModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Change value</h5>
        <button
          type="button"
          onClick={(): void => toggleEditModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {edit_items && edit_items.length && (
          <div id="edit-form" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={(): void => {
                    toggleTab(1);
                  }}
                >
                  <span className="step-number mr-2">1</span>
                  Change Reason
                </NavLink>
              </NavItem>
              {edit_items.map((item: StepForm.FieldInfo, index: number) => {
                return (
                  <NavItem key={'nav' + index}>
                    <NavLink
                      className={classnames({ active: activeTab === 2 + index })}
                      onClick={(): void => {
                        toggleTab(2 + index);
                      }}
                    >
                      <span className="step-number mr-2">{2 + index}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </NavLink>
                  </NavItem>
                );
              })}
            </ul>
            <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
              <TabPane tabId={1}>
                <Form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                  }}
                >
                  <div className="col-md-12">
                    <label className={'col-md-12 col-form-label'}>Reason for change:</label>
                    <div className="col-md-12">
                      <InputGroup>
                        <input
                          className={'form-control'}
                          name={'reason'}
                          value={reason}
                          type="text"
                          onChange={(e: any): void => {
                            setReason(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-md-12">
                      {error && <span className="validation-error">{error}</span>}
                    </div>
                  </div>
                </Form>
              </TabPane>
              {edit_items.map((item: StepForm.FieldInfo, index: number) => {
                return (
                  <TabPane tabId={2 + index} key={'tab' + index}>
                    <Form
                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <h5>Current value:</h5>
                          {renderFormField(item, true, true)}
                        </div>
                        <div className="col-md-6">
                          <h5>New value:</h5>
                          {renderFormField(item, true, false)}
                        </div>
                      </div>
                    </Form>
                  </TabPane>
                );
              })}
            </TabContent>
            <ul className="pager wizard twitter-bs-wizard-pager-link">
              <li className={activeTab === 1 ? 'previous disabled' : 'previous'}>
                <Link
                  to="#"
                  onClick={() => {
                    toggleTab(activeTab - 1);
                  }}
                >
                  Previous
                </Link>
              </li>
              <li className={'next'}>
                <Link
                  to="#"
                  onClick={() => {
                    if (edit_items && activeTab === edit_items.length + 1) {
                      handleEditSave(reason.trim());
                    } else {
                      toggleTab(activeTab + 1);
                    }
                  }}
                >
                  {activeTab === edit_items.length + 1 ? 'Save' : 'Next'}
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default FormEditModal;
