/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';
import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

/**
 * Props for FoldableReview component.
 */
export interface FoldableReviewProps {
  /** Step data. */
  step: StepForm;
  formReviewed: boolean;
}

/**
 * Used for state for [[`FoldableReview`]] component
 */
export interface FoldableReviewState {
  /** Collapse Open state for foldable section */
  isOpen: boolean;
  /** Checked state for the switch */
  isChecked: boolean;
}

/**
 * FoldableReview component
 *
 * @component FoldableReview
 * @category FormElements
 */
export default class FoldableReview extends Component<FoldableReviewProps, FoldableReviewState> {
  /**
   * Constructor for FoldableReview class component.
   */
  constructor(props: FoldableReviewProps) {
    super(props);

    // const step = props.step;

    this.state = {
      isOpen: false,
      isChecked: this.props.formReviewed ? true : false,
    };
  }

  async componentDidUpdate(prevProps: FoldableReviewProps) {
    if (this.props.formReviewed !== prevProps.formReviewed) {
      this.setState({ isChecked: this.props.formReviewed });
    }
  }

  handleSwitchReview(): void {
    const step = this.props.step;
    const oldCheckedState = this.state.isChecked;
    this.setState({ isChecked: !oldCheckedState });
    step.setReviewStatus(!oldCheckedState);
  }

  /**
   * Renders FoldableReview class component.
   */
  public render(): JSX.Element {
    const step = this.props.step;

    return (
      <div className="foldable alert-warning">
        <div
          className="foldable-title row justify-content-between align-items-center"
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          <div>
            {this.props.formReviewed ? (
              <span>
                This form was reviewed by {step?.record?.data?.created_by} on{' '}
                {moment(step?.record?.data?.created_dt).format('dddd, MMMM Do, YYYY at HH:mm')}
              </span>
            ) : (
              <span>This form requires review</span>
            )}
          </div>
          <div>
            <div className="d-inline-block">
              <span className="action-title">
                {this.state.isOpen ? 'Close History' : 'Show History'}
              </span>
              <span className="action-icon mr-1">
                <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'} />
              </span>
            </div>
          </div>
        </div>
        <div className="foldable-content">
          <Collapse isOpen={this.state.isOpen}>
            <div className="pt-2 pb-2">
              {/* Render review history */}
              <p>
                Site instructions: Review the information provided by the participant. If
                information was not entered by the participant already, ask them the question(s) and
                enter their response(s).
              </p>
              <div className="custom-control custom-switch d-inline-block mr-1">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.state.isChecked}
                  onChange={() => this.handleSwitchReview()}
                />
                <label className="custom-control-label" onClick={() => this.handleSwitchReview()}>
                  I have reviewed the information
                </label>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
