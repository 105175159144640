import React, { Component } from 'react';
import { DataContext } from '../../context/DataContext';

/**
 * Props for [[`PageBreadCrumb`]] component
 */
export interface PageBreadCrumbProps {
  /** Used for displaying replaced text. */
  logic: any;
  /** Step data. */
  text: string;
  location: { pathname: string };
}

/**
 * PageBreadCrumb component
 *
 * @component PageBreadCrumb
 * @category PageElements
 */
export default class PageBreadCrumb extends Component<PageBreadCrumbProps> {
  static contextType = DataContext;

  constructor(props: PageBreadCrumbProps) {
    super(props);
    this.state = {};
  }

  /**
   * Renders PageBreadCrumb class component.
   * @see demo page /page/text_styles in demo study.
   */
  public render(): JSX.Element {
    const text = this.props.text;
    const emptyBreadCrumb = text[0] === '/';
    const location = this.props.location;

    if (emptyBreadCrumb) {
      if (
        this.context.breadCrumbs.length == 1 &&
        this.context.breadCrumbs[0].text == text.substring(1)
      ) {
        // Prevent infinite reloading on the same page
        return <></>;
      }
      this.context.setBreadCrumbs([
        {
          text: text.substring(1),
          link: location.pathname,
        },
      ]);
    } else {
      if (!this.context.breadCrumbs.map((item: any) => item.text).includes(text)) {
        // Prevent infinite reloading on the same page, because the same item already exists in the array
        this.context.setBreadCrumbs([
          ...this.context.breadCrumbs,
          {
            text: text,
            link: location.pathname,
          },
        ]);
      } else {
        const currentIndex = this.context.breadCrumbs.map((item: any) => item.text).indexOf(text);
        if (currentIndex === this.context.breadCrumbs.length - 1) {
          // If the item is the last index, don't do anything
          return <></>;
        }
        //Remove the items after the current index
        this.context.setBreadCrumbs(this.context.breadCrumbs.slice(0, currentIndex + 1));
      }
    }
    return <></>;
  }
}
