import React from 'react';

/**
 * Imports specific from Laina Shared Platform
 */
import { StepForm } from '@lainaedge/platformshared';

/**
 * Accessible, extensible, Autocomplete for React.js.
 *
 * @remarks
 * WAI-ARIA compliant React autocomplete (combobox) component.
 * Autocomplete is a feature that suggests words or phrases that complete a user's initial input.
 *
 * Take a look at official documentation: {@link https://www.npmjs.com/package/react-autocomplete}
 * or {@link https://github.com/reactjs/react-autocomplete}
 * @packageDocumentation
 */
import Autocomplete from 'react-autocomplete';

import FormInput from './FormInput';
import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

import { InputProps } from './types';

/**
 * AutoCompleteInput component @extends FormInput
 *
 * @component AutoCompleteInput
 * @category FormElements
 */
export default class AutoCompleteInput extends FormInput {
  constructor(props: InputProps) {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
    };
  }

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  handleChangeAutocompleteInput = (field: StepForm.FieldInfo, e: any) => {
    if (this.isEditFieldOnModal()) {
      this.props.formProps.handleChangeEditValues(field, e.target.value);
    } else {
      this.setValue(field, e.target.value);
    }
  };

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelectAutocompleteInput = (field: StepForm.FieldInfo, val: any) => {
    const step = this.props.formProps.step;
    if (step && step.tableDef && step.dataDictionary) {
      /** Represents the options of the field */
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents the lookup table */
      const list = step.dataDictionary.getLookupTable(name);

      /** Represents the first item in the list which english text is equal to value. */
      const selected = list.find((item) => item.english === val);

      if (this.isEditFieldOnModal()) {
        this.props.formProps.handleChangeEditValues(field, val);
      } else {
        this.props.formProps.step.setValueFromUser(field.field, selected ? selected.code : '');

        this.setState({ myFieldValue: val });
      }
    }
  };

  /**
   * Renders AutoCompleteInput class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;
    const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    if (step.tableDef && step.dataDictionary) {
      const name = step.tableDef.getFieldLookupName(field.field);
      const list = step.dataDictionary.getLookupTable(name);

      const fieldValue = this.isEditFieldOnModal()
        ? this.props.formProps.edit_values[field.field]
        : this.state.myFieldValue;
      return (
        <>
          <div className="d-inline-block mr-2 mb-1">
            <Autocomplete
              getItemValue={(item: any) => item.english}
              items={list}
              renderItem={(item: any, isHighlighted: boolean) => (
                <div
                  key={item.english}
                  style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '5px' }}
                >
                  {item.english}
                </div>
              )}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              onChange={(e: any) => {
                this.handleChangeAutocompleteInput(field, e);
              }}
              onSelect={(val: any) => {
                this.handleSelectAutocompleteInput(field, val);
              }}
              selectOnBlur={true}
              shouldItemRender={(item: any, value: string) => {
                return item.code.toLowerCase().indexOf(value.toLowerCase()) ? false : true;
              }}
              menuStyle={{
                zIndex: 2,
                display: 'block',
                width: '100%',
                backgroundColor: '#fff',
                position: 'relative',
                top: '45px',
                left: 0,
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                maxHeight: 'min(300px, 50vh)',
                overflow: 'auto',
              }}
              inputProps={{
                className: 'form-control autocomplete',
                disabled:
                  this.isEditMode() ||
                  is_disabled ||
                  this.props.formProps.hide_fields[field.field] ||
                  this.props.formProps.field.enabled === false,
                name: field.field,
              }}
              wrapperProps={{
                className: 'w-100',
              }}
              wrapperStyle={{
                position: 'relative',
              }}
            />
          </div>
          {this.renderQnsAndUnavailableSwitches()}
        </>
      );
    } else {
      return <></>;
    }
  }
}
