import React from 'react';

import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

import FormInput from './FormInput';

import { QNS_VALUE, UNAVAILABLE_VALUE } from '../../../constants';

/**
 * SliderInput component
 *
 * @component SliderInput
 * @category FormElements
 */
export default class SliderInput extends FormInput {
  /**
   * Toggles the value of a field between 'Yes' or 'No'.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  handleChangeValue = (value: string) => {
    const field = this.props.formProps.field;
    this.setValue(field, value);
  };

  /**
   * Renders SliderInput class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    let className = '';
    let useDefault = true;
    let max = 0;
    let min = 1;
    if (this.props.formProps.errors[field.field]) className += ' is-invalid';

    const labels: any = {};
    let list: any[] = [];
    if (step.tableDef && step.dataDictionary) {
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents a lookup table */
      list = step.dataDictionary.getLookupTable(name);

      if (list && list.length) {
        list.map((option) => {
          labels[option.code] = option.english;
          if (Number(option.code) > max) {
            max = Number(option.code);
          }
          if (Number(option.code) < min) {
            min = Number(option.code);
          }
        });
        useDefault = false;
      }
    }

    console.log(list);

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : Number(this.state.myFieldValue);
    return (
      <>
        <div>
          <div className="custom-slider">
            <Slider
              min={useDefault ? 0 : min}
              max={useDefault ? 5 : max}
              className={className}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              labels={
                useDefault
                  ? { 1: 'Very Easy', 2: 'Easy', 3: 'Medium', 4: 'Hard', 5: 'Very Hard' }
                  : labels
              }
              disabled={this.isFieldDisabled()}
              onChange={(value: any) => {
                if (this.isEditFieldOnModal()) {
                  this.props.formProps.handleChangeEditValues(field, value);
                } else {
                  this.handleChangeValue(value);
                }
              }}
              format={(e) => {
                const lookupItem = list.find((item) => item.code == e);
                return lookupItem?.custom_code ? lookupItem?.custom_code : lookupItem?.code;
              }}
              orientation="horizontal"
            />
          </div>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
      </>
    );
  }
}
