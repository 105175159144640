import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import { useData } from '../context/DataContext';

/**
 * NonAuthLayout component.
 *
 * @remarks
 * Layout for guest users
 *
 * @component NonAuthLayout
 * @category Component
 */
const NonAuthLayout = () => {
  const location = useLocation();
  const { versionInfo } = useData();
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(1).toUpperCase() + str.slice(2);
  };

  useEffect(() => {
    const currentage = capitalizeFirstLetter(location.pathname);
    document.title = currentage + ' | V' + versionInfo?.version + ' | LAINA EDC PORTAL';
  }, [versionInfo, location.pathname]);

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
};

export default NonAuthLayout;
